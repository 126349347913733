import React, { useContext, useEffect, useState, useRef } from "react";
import { Breadcrumb } from "antd";
import { Context } from "../../../Context/Context";
import Summary from "./Summary";
import { MdOutlineArrowUpward } from "react-icons/md";
import {
  createChannelInsightThread,
  createThreadConversation,
  getThreadByChannelInsight,
} from "../../../services/chatService";
import { IoMdArrowRoundBack } from "react-icons/io";
import { FaCircleXmark } from "react-icons/fa6";
import { motion } from "framer-motion";

const InsightDetails = ({setLoadNotesTrigger, loadNotesTrigger, chatRef }) => {
  const {
    knowledgespace,
    pathIndex,
    darkTheme,
    allThreadData,
    setAllThreadData,
    summaryInfo,
    setShowChat,
    setMessages,
    setAnswerLoading,
    loadingInsight,
    insightDetails,
    actionState,
    setActionState,
    isPdfAvailable,
    setIsPdfAvailable,
  } = useContext(Context);
  const [path, setPath] = useState([]);
  const [message, setMessage] = useState("");
  const inputRef = useRef(null);
  useEffect(() => {
    inputRef.current?.focus();
  });

  useEffect(() => {
    const updatePath = () => {
      let newPath = [];
      if (pathIndex?.workspace_id !== null) {
        const workspace = knowledgespace.find(
          (ws) => ws.id === pathIndex.workspace_id
        );
        if (workspace) {
          newPath.push(workspace.name);
          if (pathIndex?.channel_id !== null) {
            const channel = workspace.channels.find(
              (ch) => ch.id === pathIndex?.channel_id
            );
            if (channel) {
              newPath.push(channel.name);
            }
          }
        }
      }
      if (newPath.length > 0) setPath(newPath);
    };
    updatePath();
  }, [pathIndex]);
  const handleInputChangeMessage = (e) => {
    setMessage(e.target.value);
  };

  const handleSendMessage = async () => {
    try {
      if (message.trim() === "") return;
      setShowChat(true);
      setActionState(1);
      const currentMessage = message;
      const userMessage = { text: currentMessage, sender: "user" };
      setMessages((prev) => [...prev, userMessage]);
      setMessage("");
      setAnswerLoading(true);
      if (allThreadData && allThreadData?.length === 0) {
        const createResponse = await createChannelInsightThread(
          pathIndex.channel_id,
          pathIndex.insight_id
        );
        if (createResponse && createResponse?.data) {
          const thread_data = await getThreadByChannelInsight(
            pathIndex.channel_id,
            pathIndex.insight_id
          );

          if (thread_data?.data) {
            setAllThreadData(thread_data?.data);
            const response = await createThreadConversation(
              thread_data?.data[0].thread_id,
              currentMessage
            );
            setTimeout(() => {
              const botMessage = { text: response?.data.answer, sender: "bot" };
              setMessages((prev) => [...prev, botMessage]);
            }, 1000);
          }
        }
      } else {
        const response = await createThreadConversation(
          allThreadData[0].thread_id,
          currentMessage
        );
        setTimeout(() => {
          const botMessage = { text: response?.data.answer, sender: "bot" };
          setMessages((prev) => [...prev, botMessage]);
        }, 1000);
      }
    } catch (error) {
      console.error("Error occurred:", error);
      throw error;
    } finally {
      setAnswerLoading(false);
    }
  };

  return (
    <div
      className={`flex flex-col h-full 2-full z-[8] border-r border-[1px] ${darkTheme ? "bg-[#181818] border-[#ffffff16]" : "bg-[#fafbfa]"
        }`}
    >
      {/* Header Breadcrumb */}
      <div
        className={` border-b ${darkTheme
          ? "bg-[#181818] border-[#ffffff16]"
          : "bg-[#fafbfa] border-[#00000016]"
          } `}
      >
        <div
          className={`flex flex-row justify-between items-center p-[1px] ${darkTheme ? "border-[#ffffff16]" : "border-[#00000016]"
            }`}
        >
          <div
            className={`pl-2 cursor-pointer ${actionState === null ? "hidden" : ""
              }`}
            onClick={() => {
              setActionState(null);
              setIsPdfAvailable(false);
            }}
          >
            <FaCircleXmark
              className={`w-5 h-5 rounded-full ${darkTheme ? "bg-black text-white" : "text-black bg-white"
                }`}
            />
          </div>

          <div className="p-2 mx-2 mt-[1px] min-h-[40px] flex-grow">
            <Breadcrumb
              separator={
                <span
                  className={`${darkTheme ? "text-white" : "text-[#2f2f2f]"}`}
                >
                  {">>"}
                </span>
              }
              items={path.map((p, index) => ({
                title: (
                  <span
                    className={`${darkTheme ? "text-white" : "text-[#2f2f2f]"
                      } font-semibold`}
                  >
                    {p.split(" ")[0].charAt(0).toUpperCase() +
                      p.split(" ")[0].slice(1)}
                  </span>
                ),
                key: index,
              }))}
            />
          </div>
          <motion.div
            className={`flex py-[2px] px-[2px] mt-[1px] rounded-md overflow-hidden shadow-sm ${actionState === null || insightDetails?.source !== "pdf"
              ? "hidden"
              : ""
              } ${darkTheme ? "bg-[#303030] text-white" : "bg-gray-200"}`}
            initial={{ opacity: 0, scale: 1 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
          >
            <button
              onClick={() => setIsPdfAvailable(true)}
              className={`relative w-16 py-[3px] px-2 font-semibold text-[12px] tracking-wider transition-all duration-300 ease-in-out rounded-md ${isPdfAvailable ? "bg-white text-black" : ""
                }`}
            >
              PDF
            </button>
            <button
              onClick={() => setIsPdfAvailable(false)}
              className={`relative w-16 py-[3px] px-2 font-semibold text-[12px] tracking-wider transition-all duration-300 ease-in-out rounded-md ${!isPdfAvailable ? "bg-white text-gray-700" : ""
                }`}
            >
              Insight
            </button>
          </motion.div>
        </div>
      </div>

      <div
        className={`flex overflow-auto px-2 ${actionState === null ? "mr-[50px] 3xl:mr-[70px]" : ""
          } ${darkTheme ? "dark-theme" : "light-theme"
          } custom-scrollbar scroll-container`}
      >
        {summaryInfo !== null && <Summary setLoadNotesTrigger = {setLoadNotesTrigger} loadNotesTrigger = {loadNotesTrigger} chatRef = {chatRef} />}
      </div>
      {summaryInfo !== null &&
        !loadingInsight &&
        insightDetails &&
        actionState === null && (
          <div
            className={`flex justify-center sm:py-4 3xl:py-6 px-8 ${darkTheme ? "" : ""
              }`}
          >
            <div
              className={`flex sm:w-[90%] 3xl:w-[65%] 3xll:w-[60%] 4xl:w-[70%] ${darkTheme
                ? "bg-[#323232] shadow-[0_0_3px_1px_rgba(59,140,246,0.75),0_0_1px_1px_rgba(59,130,246,0.5),0_0_1px_1px_rgba(59,130,246,0.25)]"
                : "bg-white shadow-[0_0_3px_1px_rgba(59,130,246,0.75),0_0_1px_1px_rgba(59,130,246,0.5),0_0_1px_1px_rgba(59,130,246,0.25)]"
                } pl-4 px-2 rounded-full py-1`}
            >
              <textarea
                ref={inputRef}
                value={message}
                onChange={handleInputChangeMessage}
                onKeyDown={(e) => {
                  if ((e.key === "Enter" && e.shiftKey) || (e.key === "Enter" && e.ctrlKey)) {
                    e.preventDefault();
                    const cursorPosition = e.target.selectionStart;
                    const updatedMessage =
                      message.slice(0, cursorPosition) + '\n' + message.slice(cursorPosition);
                    handleInputChangeMessage({ target: { value: updatedMessage } });
                  } else if (e.key === "Enter") {
                    e.preventDefault();
                    handleSendMessage();
                  }
                }}
                className={`flex-grow sm:py-1 3xl:py-4 px-2 rounded-xl justify-center items-center focus:outline-none ${darkTheme
                    ? "bg-[#323232] text-white placeholder-gray-300 border-[#3d4144]"
                    : "bg-[#fbfbfa] text-black placeholder-[#acacad] border-[#c7c7c7]"
                  }`}
                placeholder="Chat with the insight..."
                rows={1}
                style={{
                  "scrollbar-width": "none"
                }}
              />
              <button
                onClick={handleSendMessage}
                className={`sm:p-2 3xll:p-4 text-white rounded-full ${message?.length > 0
                  ? "bg-[#f15025] hover:bg-[#f15025]"
                  : "bg-[#404040]"
                  }`}
              >
                <MdOutlineArrowUpward className="h-6 w-6" />
              </button>
            </div>
          </div>
        )}
    </div>
  );
};

export default InsightDetails;
