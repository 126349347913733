import React, { useState, useEffect, useContext } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Loader2 } from "lucide-react";
import Masonry from "react-masonry-css";
import { Context } from "../../Context/Context";
import Layout from "../Layout";
import { getDashboardDetails } from "../../services/dashboardService";
import { AiOutlineHome, AiOutlineInbox } from "react-icons/ai";
import { MdOutlineDashboard } from "react-icons/md";
import { MdOutlineInsights } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useDashData } from "../../hooks/useDashData";
import SideNavigation from "../../components/SideNavigation/SideNavigation";

const Skeleton = ({ height }) => {
  const { darkTheme } = useContext(Context);
  return (
    <div className={`animate-pulse space-y-2 ${height}`}>
      <div
        className={`h-4 ${darkTheme ? "bg-gray-700" : "bg-gray-200"
          } rounded w-3/4`}
      ></div>
      <div
        className={`h-4 ${darkTheme ? "bg-gray-700" : "bg-gray-200"
          } rounded w-1/2`}
      ></div>
      <div
        className={`h-4 ${darkTheme ? "bg-gray-700" : "bg-gray-200"
          } rounded w-5/6`}
      ></div>
    </div>
  );
};

const Card = ({ title, channel_id, space_id, content, itemCount, height = "auto" }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { darkTheme } = useContext(Context);
  const [hoveredItem, setHoveredItem] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    const timer = setTimeout(
      () => setIsLoading(false),
      Math.random() * 2000 + 1000
    );
    return () => clearTimeout(timer);
  }, []);
  const handleClick = (insight_id) => {
    navigate(`/home/sp/${space_id}/ch/${channel_id}/in/${insight_id}`, { replace: true });
  }
  return (
    <div
      className={`rounded-lg shadow p-4 mb-6 overflow-hidden flex flex-col w-full ${darkTheme ? "bg-[#1E201E]" : "bg-white"
        }`}
    >
      <div className="flex justify-between items-center mb-2">
        <h2 className="text-md font-semibold">{title}</h2>
        <span
          className={`text-sm ${darkTheme ? "text-orange-600" : "text-orange-600"
            }`}
        >
          {itemCount > 5 ? "5+" : itemCount}
        </span>
      </div>
      <div
        className={`${darkTheme
          ? "border-[1px] border-[#273037]"
          : "border-[1px] border-gray-200"
          } mb-4`}
      ></div>
      <AnimatePresence>
        {isLoading ? (
          <motion.div
            key="loading"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className=""
          >
            <Skeleton height={height} />
          </motion.div>
        ) : (
          <ul className={`space-y-1 overflow-y-auto hide-scrollbar custom-scrollbar`}>
            {content.map((item, index) => (
              <li
                key={index}
                className="flex text-sm items-center cursor-pointer py-[3px]"
                onClick={() => handleClick(item.insight_id)}
                onMouseEnter={() => setHoveredItem(item.insight_id)}
                onMouseLeave={() => setHoveredItem(null)}
              >
                {item.logo && (
                  <span
                    className={`text-xs ${darkTheme ? "text-gray-400" : "text-gray-500"
                      }`}
                  >
                    <img
                      src={item.logo}
                      alt="logo"
                      className={`w-4 h-4`}
                      onError={(e) => {
                        e.currentTarget.src = "/logo1.png";
                      }}
                    />
                  </span>
                )}
                {!item.logo && item.logo1 && (
                  <span
                    className={`text-xs ${darkTheme ? "text-gray-400" : "text-gray-500"
                      }`}
                  >
                    <img
                      src={item.logo1}
                      alt="logo"
                      className={`w-4 h-4`}
                      onError={(e) => {
                        e.currentTarget.src = "/logo1.png";
                      }}
                    />
                  </span>
                )}
                {!item.logo && !item.logo1 && (
                  <span
                    className={`text-xs ${darkTheme ? "text-gray-400" : "text-gray-500"
                      }`}
                  >
                    <img
                      src="/logo1.png"
                      alt="logo"
                      className={`w-4 h-4 ${darkTheme ? "" : "filter invert"}`}
                      onError={(e) => {
                        e.currentTarget.src = "/logo1.png";
                      }}
                    />
                  </span>
                )}
                <div className="hidden sm:block xl:hidden">
                  <span
                    className={`ml-4 text-[16px] py-2 ${hoveredItem === item.insight_id
                        ? "text-blue-500"
                        : darkTheme
                          ? "text-gray-400"
                          : "text-gray-700"
                      }`}
                  >
                    {item.text.slice(0, 43)} {item.text.length > 43 ? "..." : ""}
                  </span>
                </div>
                <div className="hidden xl:block 3xl:hidden">
                  <span
                    className={`ml-4 text-[16px] py-2 ${hoveredItem === item.insight_id
                        ? "text-blue-500"
                        : darkTheme
                          ? "text-gray-400"
                          : "text-gray-700"
                      }`}
                  >
                    {item.text.slice(0, 48)} {item.text.length > 48 ? "..." : ""}
                  </span>
                </div>
                <div className="sm:hidden">
                  <span
                    className={`ml-4 text-[14px] py-2 ${hoveredItem === item.insight_id
                        ? "text-blue-500"
                        : darkTheme
                          ? "text-gray-400"
                          : "text-gray-700"
                      }`}
                  >
                    {item.text.slice(0, 28)} {item.text.length > 28 ? "..." : ""}
                  </span>
                </div>
                <div className="hidden 3xl:block">
                  <span
                    className={`ml-4 text-[16px] py-2 ${hoveredItem === item.insight_id
                        ? "text-blue-500"
                        : darkTheme
                          ? "text-gray-400"
                          : "text-gray-700"
                      }`}
                  >
                    {item.text.slice(0, 90)} {item.text.length > 90 ? "..." : ""}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        )}
      </AnimatePresence>
    </div>
  );
};

function Dashboard() {
  const [spaces, setSpaces] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { darkTheme, dashboardData } = useContext(Context);
  useEffect(() => {
    const channelsWithSpaceData = dashboardData?.flatMap(space =>
      space.channels.map(channel => ({
        ...channel,
        space_id: space.space_id,
        space_name: space.space_name,
        space_no_of_channels: space.no_of_channels,
        space_total_insights: space.total_insights,
      }))
    );

    channelsWithSpaceData?.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
    setSpaces(channelsWithSpaceData);
    setIsLoading(false);
  }, [dashboardData]);

  const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    700: 2,
    500: 1,
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="flex justify-center items-center h-screen scroll-container custom-scrollbar hide-scrollbar">
          <Loader2
            className={`w-12 h-12 animate-spin ${darkTheme ? "text-gray-300" : "text-blue-500"}`} />
        </div>
      );
    }

    if (spaces?.length === 0) {
      return (
        <>
          <div className="flex flex-col items-center justify-center h-screen">
            <p className={`text-lg  ${darkTheme ? "text-gray-300" : "text-gray-700"}`}>
              No spaces or channels found.
            </p>
          </div>
        </>
      );
    }

    return (
      <div className="overflow-y-auto h-full hide-scrollbar custom-scrollbar px-2 py-1">
        <Masonry
          breakpointCols={breakpointColumnsObj}
          spacing={2}
          className="flex w-full sm:ml-[-12px] mb-10 sm:mb-4"
          columnClassName="w-[30%] sm:pl-[15px]"
        >
          {spaces?.map((channel) =>
            <Card
              key={channel.channel_id}
              space_id={channel.space_id}
              channel_id={channel.channel_id}
              title={`${channel.space_name} > ${channel.name}`}
              content={channel.insight_details.map((insight) => ({
                text: insight.content.title,
                url: insight.url,
                logo: insight.content.meta_favicon,
                logo1: insight.content.logo,
                insight_id: insight.insight_id
              }))}
              itemCount={channel.no_of_insights}
            />
          )}
        </Masonry>
      </div>
    );
  };

  return (
    <div
      className={`flex flex-col h-full w-[100%] px-4 pb-2 transition-all duration-300 rounded-tl-lg overflow-none ${darkTheme ? "bg-[#181818] border-[#ffffff16]" : "bg-[#fafbfa]"
        }`}
    >
      <div className={`flex-shrink-0 pl-10 py-1 ml-[-15px] border-b ${darkTheme ? "border-[#ffffff16]" : "border-gray-200"}`}>
        <h1 className={`text-xl font-bold ${darkTheme ? "text-gray-300" : "text-gray-700"}`}>Overview</h1>
      </div>
      <div className="flex-grow overflow-hidden py-4">
        {renderContent()}
      </div>

    </div>
  );
}
export default function DashboardPage() {
  const { darkTheme, activeSection, setActiveSection } = useContext(Context);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const handleSectionChange = (index) => {
    setActiveSection(index);
  };

  useDashData(setIsLoading);

  return (
    <Layout>
      <div className="hidden sm:block">
        <SideNavigation />
      </div>
      <div
        className={`relative hidden sm:flex mt-0 sm:mt-10 sm:ml-[49px] h-[calc(100vh-2.5rem)] w-[100%] sm:w-[98%] border-t
          ${darkTheme
            ? "bg-[#181818] border-[#ffffff16]"
            : "bg-[#fafbfa] border-[#00000016]"
          }`}
      >
        <Dashboard />
      </div>
      <div className={`sm:hidden w-screen mt-8 sm:mt-10 h-[95%] p-2`}>
        <div className={`${activeSection === 1 ? "block" : "hidden"}`}>
          <Dashboard />
        </div>
      </div>
      <div
        className={`fixed z-[12] bottom-0 w-full sm:hidden border-t ${darkTheme
          ? "bg-[#181818] border-[#ffffff16]"
          : "bg-white border-[#00000016]"
          }`}
      >
        <div className={`grid grid-cols-4 gap-1 py-1 px-1`}>
          <button
            className={`flex justify-center rounded-lg p-4 ${activeSection === 0
              ? darkTheme
                ? "bg-[#2d2d2e]"
                : "bg-[#e8e8e9]"
              : darkTheme
                ? "text-white"
                : "text-black"
              }`}
            onClick={() => {
              navigate("/home");
              handleSectionChange(0);
            }}
          >
            <AiOutlineHome />
          </button>
          <button
            className={`flex justify-center rounded-lg p-4 ${activeSection === 1
              ? darkTheme
                ? "bg-[#2d2d2e]"
                : "bg-[#e8e8e9]"
              : darkTheme
                ? "text-white"
                : "text-black"
              }`}
            onClick={() => {
              navigate("/dashboard");
              handleSectionChange(1);
            }}
          >
            <MdOutlineDashboard />
          </button>
          <button
            className={`flex justify-center rounded-lg p-4 ${activeSection === 2
              ? darkTheme
                ? "bg-[#2d2d2e] "
                : "bg-[#e8e8e9]"
              : darkTheme
                ? "text-white"
                : "text-black"
              }`}
            onClick={() => {
              navigate("/home");
              handleSectionChange(2);
            }}
          >
            <AiOutlineInbox />
          </button>
          <button
            className={`flex justify-center rounded-lg p-4 ${activeSection === 3
              ? darkTheme
                ? "bg-[#2d2d2e]"
                : "bg-[#e8e8e9]"
              : darkTheme
                ? "text-white"
                : "text-black"
              }`}
            onClick={() => {
              navigate("/home");
              handleSectionChange(3);
            }}
          >
            <MdOutlineInsights />
          </button>
        </div>
      </div>
    </Layout>
  );
}
