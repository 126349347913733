import React, { useContext, useState } from 'react';
import { motion } from 'framer-motion';
import { MdOutlineDashboard } from "react-icons/md";
import { AiOutlineHome } from "react-icons/ai";
import { BsToggle2Off } from 'react-icons/bs';
import { IoLogOutOutline } from 'react-icons/io5';
import { Context } from '../../Context/Context';
import LoginModal from '../../components/LoginModal/LoginModal';
const SideNavigationDummy = ({overlay}) => {
    const { darkTheme, toggleTheme} = useContext(Context);
    const [isLoginModalOpen, setLoginModalOpen] = useState(false);

    const menuItems = [
        { id: 'Home', icon: AiOutlineHome, label: 'Home', nav_link: '/home' },
        { id: 'Dashboard', icon: MdOutlineDashboard, label: 'Dashboard', nav_link: '/dashboard' },
    ];

    const handleItemClick = () => {
        setLoginModalOpen(true); // Open the login modal
    };
    return (
        <>
            <motion.div
                className={`fixed left-0 top-0 h-screen w-[50px] z-[22] border-r ${darkTheme ? "bg-[#202020] border-[#ffffff16]" : "bg-[#F6F5F3] border-[#3d3d3d2d]"} flex flex-col justify-between items-center`}
            >
                {/* Top section */}
                <motion.div className={`mt-1 border-b border-[#ffffff16]`}>
                    <img src="/logo1.png" alt="Logo" className={`w-[50px] h-[36px] my-auto ${darkTheme ? "" : "filter invert"}`} />
                </motion.div>

                {/* Menu Items */}
                <motion.div className="flex flex-col flex-grow items-center justify-center space-y-4">
                    {menuItems.map((item) => (
                        <motion.div
                            key={item.id}
                            className="w-full mb-1 relative cursor-pointer"
                            onClick={handleItemClick} // Trigger modal
                        >
                            <motion.div className="flex items-center justify-center font-extralight rounded-lg">
                                <item.icon className='text-[20px]' />
                            </motion.div>
                        </motion.div>
                    ))}
                </motion.div>

                {/* Bottom Section */}
                <motion.div className="flex flex-col items-center space-y-4 mb-8">
                    {/* Light-Dark Theme Switch */}
                    <motion.div
                        className="relative cursor-pointer"
                        onClick={toggleTheme} // Prevent modal here
                    >
                        <motion.div className="flex items-center justify-center rounded-lg">
                            <BsToggle2Off className='text-[20px]' />
                        </motion.div>
                    </motion.div>

                    {/* Logout Button */}
                    <motion.div
                        className="relative cursor-pointer"
                        onClick={handleItemClick} // Trigger modal
                    >
                        <motion.div className="flex items-center justify-center font-extralight text-red-500 rounded-lg">
                            <IoLogOutOutline className='text-[20px]' />
                        </motion.div>
                    </motion.div>
                </motion.div>
            </motion.div>

            {/* Login Modal */}
            {isLoginModalOpen && (
                <LoginModal handleClose={() => setLoginModalOpen(false)} isOpen = {isLoginModalOpen}/>
            )}
        </>
    );
};

export default SideNavigationDummy;
