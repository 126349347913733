import React, { useContext, useEffect, useState, useRef } from "react";
import { IoSearch } from "react-icons/io5";
import "react-loading-skeleton/dist/skeleton.css";
import { GoDotFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { format } from 'date-fns';
import { Context } from "../../Context/Context";
import { getPublicInsightDetails } from "../../services/publicAPIs";

const InsightContainer = ({ darkTheme, isListLoading, filteredInsights, renderInsightCard, searchQuery }) => {
  const scrollContainerRef = useRef(null);
  const groupInsightsByDate = (insights) => {
    const groupedInsights = {};
    insights.forEach(insight => {
      const insightDate = format(new Date(insight.time), "MMMM dd, yyyy");
      if (!groupedInsights[insightDate]) {
        groupedInsights[insightDate] = [];
      }
      groupedInsights[insightDate].push(insight);
    });
    return groupedInsights;
  };

  const sortedGroupedInsights = groupInsightsByDate(filteredInsights || []);
  const highlightText = (text) => {
    if (!searchQuery) return text; // Return original text if no query

    const regex = new RegExp(`(${searchQuery})`, 'gi'); // Create a regex to match the query
    const parts = text.split(regex); // Split the text by matched query

    return parts.map((part, index) =>
      part.toLowerCase() === searchQuery.toLowerCase() ? (
        <span key={index} className="bg-yellow-400">{part}</span> // Highlight matched part
      ) : (
        part // Return non-matched part
      )
    );
  };

  return (
    <div
      ref={scrollContainerRef}
      className={`flex flex-col h-screen overflow-y-auto scroll-container custom-scrollbar ${darkTheme ? 'dark-theme' : 'light-theme'}`}
    >
      {isListLoading ? (
        <div className="w-full p-2">
          <SkeletonTheme baseColor={darkTheme ? "#1e1e1e" : "#dddddd"} highlightColor="#444">
            <Skeleton height={70} count={10} />
          </SkeletonTheme>
        </div>
      ) : (
        Object.entries(sortedGroupedInsights).map(([date, insights]) => (
          <React.Fragment key={date}>
            {<div className={`font-bold text-[16px] sm:text-[14px] ${darkTheme ? "text-[#545657]" : "text-[#273037]"} p-2`}>{date}</div>}
            <div className={`w-auto sm:w-auto  mb-2 sm:mb-0 m-2 sm:p-0 sm:m-2 rounded-lg ${darkTheme ? "bg-[#181818] text-white" : "bg-[#F4F4F6] text-black"}`}>
              {insights.sort((a, b) => new Date(b.time) - new Date(a.time)).map((item, index, arr) => renderInsightCard(item, index, arr)
              )}
            </div>
          </React.Fragment>
        ))
      )}
      <div className="mb-32"></div>
    </div>
  );
};

const SharedInsightList = ({ insightList, insightId, setResponseData, setInsightLoading, isListLoading, location, user_name}) => {
  const {
    darkTheme,
    setActiveSection,
  } = useContext(Context);

  const [selectedInsightId, setSelectedInsightId] = useState(insightId);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [filteredInsights, setFilteredInsights] = useState(null);
  const inputRef = useRef(null);
  const timeoutRef = useRef(null);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchChannelInsights = async () => {
      try {
        setFilteredInsights(insightList);
      } catch (error) {
        console.error("Error fetching channel insights:", error);
      }
    };

    fetchChannelInsights();
    setSelectedInsightId(insightId)
  }, [insightList]);

  const handleIssueClick = async (insight) => {
    try {
      setInsightLoading(true);
      if (insight.id) {
        if(location.pathname.includes('/history')){
          navigate(
            `/history/${insight.id}`,
            { replace: true }
          );  
        }
        else if(location.pathname.includes('/user/')){
          navigate(
            `/user/${user_name}/in/${insight.id}`,
            { replace: true }
          );  
        }
        setSelectedInsightId(insight.id); 
        const response = await getPublicInsightDetails(insight.id);
        if (response && response?.data) {
          setResponseData(response.data)
          setInsightLoading(false);
        }
      }
    } catch (error) {
      console.error("Error fetching insight details:", error);
      setInsightLoading(false);
    }
    finally {
      setInsightLoading(false);
    }
  };
    useEffect(() => {
      let filtered = insightList;
  
      if (searchQuery) {
        filtered = filtered.filter(
          (insight) =>
            (insight.title &&
              insight.title.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (insight.description &&
              insight.description
                .toLowerCase()
                .includes(searchQuery.toLowerCase()))
        );
      }
      setFilteredInsights(filtered);
    }, [searchQuery]);

  const renderInsightCard = (item, index, arr) => (
    <div
      key={item.id}
      onClick={async () => {
        await handleIssueClick(item);
        setActiveSection(3);
      }}
      className="w-full cursor-pointer"
    >
      <div
        className={`flex overflow-hidden border ${darkTheme
          ? "hover:bg-[#2d2d2e] border-[#ffffff16]"
          : "hover:bg-[#e8e8e9]"
          } ${selectedInsightId === item.id
            ? darkTheme
              ? "bg-[#2d2d2e]"
              : "bg-[#e8e8e9]"
            : ""
          } rounded-md p-3 sm:p-3 lg:p-4`}
      >
        {item.source === 'pdf' && <img
          src={item.logo}
          alt="Example"
          className={`w-20 h-20 sm:w-8 sm:h-8 lg:w-12 lg:h-12 sm:mr-2 lg:mr-4`}
          onError={(e) => {
            e.currentTarget.onerror = null; // Prevents looping in case the fallback fails
            e.currentTarget.src =
              "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/625px-PDF_file_icon.svg.png"; // Replace with the path to your default image
          }}
        />}
        {(item.source === 'web' || item.source === 'youtube') && <img
          src={item.image}
          alt="Example"
          className={`w-20 h-20 sm:w-8 sm:h-8 lg:w-12 lg:h-12 sm:mr-2 lg:mr-4`}
          onError={(e) => {
            e.currentTarget.onerror = null; // Prevents looping in case the fallback fails
            e.currentTarget.src = "/logo.png"; // Replace with the path to your default image
          }}
        />}
        {item.logo && (
          <img
            src={item.logo}
            alt="Example"
            className={`w-6 h-6 mt-16 sm:w-3 sm:h-3 sm:mt-8 lg:w-4 lg:h-4 lg:mt-9 ml-[-18px] md:ml-[-20px] lg:ml-[-25px] md:mr-1 lg:mr-3`}
            onError={(e) => {
              e.currentTarget.onerror = null;
              e.currentTarget.src = "/logo.png";
            }}
          />
        )}
        <div className="flex flex-col flex-grow overflow-hidden px-2 sm:px-0">
          <p className="text-[16px] sm:text-[12px] lg:text-[16px] sm:truncate sm:w-full">
            <span className="sm:hidden">{highlightText(item.title)}</span>
            <span
              className="hidden sm:inline"
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {highlightText(item.title)}
            </span>
          </p>
          <p className="hidden text-[9px] sm:text-[10px] sm:block lg:text-[14px] text-gray-400 sm:truncate sm:w-full">
            <span className="sm:hidden">
              {highlightText(item.description) || "No description Available"}
            </span>
            <span
              className="hidden sm:inline"
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {highlightText(item.description) || "No description Available"}
            </span>
          </p>
        </div>
      </div>
      <div className="flex justify-center">
        <div
          className={`${index === arr.length - 1 ? "w-0" : "w-[90%]"} ${darkTheme ? "border-[1px] border-[#2d2d2e]" : ""
            }`}
        ></div>
      </div>
    </div>
  );

  const handleSearchBlur = () => {
    timeoutRef.current = setTimeout(() => {
      searchQuery === ""
        ? setIsSearchActive(!isSearchActive)
        : setIsSearchActive(isSearchActive);
    }, 200);
  };

  const handleSearchFocus = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };
  const highlightText = (text) => {
    if (!searchQuery) return text; // Return original text if no query
    if (text === null) return "";
    const regex = new RegExp(`(${searchQuery})`, "gi"); // Create a regex to match the query
    const parts = text !== null ? text.split(regex) : []; // Split the text by matched query

    return parts.map((part, index) =>
      part.toLowerCase() === searchQuery.toLowerCase() ? (
        <span key={index} className="bg-yellow-400">
          {part}
        </span> // Highlight matched part
      ) : (
        part
      )
    );
  };

  return (
    <div
      className={`sm:min-h-screen lg:w-[350px] xl:w-[400px] 2xl:w-[450px] rounded-tl-lg border transition-all duration-200 sm:mb-0 ${darkTheme ? "bg-[#181818] border-[#ffffff16]" : "bg-[#fafbfa]"
        }`}
    >
      <div
        className={`flex justify-between items-center px-4 sm:p-0 border-b ${darkTheme ? "border-[#ffffff16]" : "border-[#3d3d3d2d]"
          }`}
      >
        {
          <div
            className={`${darkTheme ? "text-white" : "text-[#000000] font-semibold"
              } p-2 sm:mx-2`}
          >
            Inbox
          </div>
        }
        <div
          className={`flex p-[1px] items-center gap-2`}
        >
          <div className="relative m-1 mr-4 pt-[1px] flex items-center">
            {!isSearchActive && (
              <IoSearch
                onClick={() => setIsSearchActive(true)}
                className={`cursor-pointer my-2 transition-all duration-200 ease-in-out ${darkTheme ? "text-white" : "text-black"
                  }`}
              />
            )}
            {isSearchActive && (
              <input
                ref={inputRef}
                type="text"
                placeholder="Search insights"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className={`pl-8 pr-4 py-1 rounded-md outline-none transition-all duration-200 ease-in-out ${isSearchActive
                  ? "w-[130px] sm:w-[150px] lg:w-[200px]"
                  : "w-0"
                  } ${darkTheme
                    ? "bg-[#2d2d2e]  text-white"
                    : "bg-[#e8e8e9] text-black"
                  }`}
                onBlur={handleSearchBlur}
                onFocus={handleSearchFocus}
                autoFocus
              />
            )}
            {isSearchActive && (
              <IoSearch
                className={`absolute left-2 top-1/2 transform -translate-y-1/2 ${darkTheme ? "text-white" : "text-black"
                  }`}
              />
            )}
          </div>
        </div>
      </div>
      <div className="h-screen">
        <InsightContainer
          darkTheme={darkTheme}
          isListLoading={isListLoading}
          filteredInsights={filteredInsights}
          renderInsightCard={renderInsightCard}
          searchQuery={searchQuery}
        />
      </div>
    </div>
  );
};

export default SharedInsightList;
