import React, { useContext } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import { Context } from "../Context/Context";
import SideNavigation from "../components/SideNavigation/SideNavigation";
import SideNavigationDummy from "./shareScreen/SideNavigationDummy";
const Layout = ({ children }) => {
    const { darkTheme } = useContext(Context);
    const isLoggedIn = localStorage.getItem('access_token') !== null;
    return (
        <div className={`flex h-fit ${darkTheme ? "bg-[#0e0f0e] text-text-dark" : "bg-[#F6F5F3] text-black"}`}>
            <div className="hidden sm:block">
                {isLoggedIn ? <SideNavigation /> : <SideNavigationDummy />}
            </div>
            <div className="fixed top-0 left-0 right-0 sm:hidden flex justify-center items-center">
                <div className={`mt-1 border-b ${darkTheme ? "border-[#ffffff16]" : "border-[#00000016]"}`}>
                    <img
                        src="/logo1.png"
                        alt=""
                        className={`w-[44px] h-[32px] my-auto ${darkTheme ? "" : "filter invert"}`}
                    />
                </div>
                <h3 className={`${darkTheme ? "text-gray-200" : "text-gray-800"} ml-2`}>
                    Tensorweave
                </h3>
            </div>
            <div className="flex-1 flex flex-col scroll-container custom-scrollbar hide-scrollbar">
                {children}
            </div>
        </div>
    );
};

export default Layout;
