import React, { useContext, useEffect, useState } from 'react';
import { Camera, X, Save, Edit2 } from 'lucide-react';
import { Context } from '../../Context/Context';
import { getUserProfile, updateUserProfile, uploadUserProfileImage, validateUsername } from '../../services/userService';
import Layout from '../Layout';
import SideNavigation from '../../components/SideNavigation/SideNavigation';
import ProfilePageSkeleton from './ProfilePageSkeleton';

const ProfilePage = () => {
  const { userData, setUserData, darkTheme } = useContext(Context);
  const [isEditing, setIsEditing] = useState(false);
  const [profile, setProfile] = useState(userData || {});
  const [loading, setIsLoading] = useState(true);
  const [isUsernameValid, setIsUsernameValid] = useState(true);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const user_data = await getUserProfile();
      if (user_data?.data) {
        setUserData(user_data.data);
        setProfile(user_data.data);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!isEditing && userData) {
      setProfile(userData);
    }
  }, [isEditing, userData]);

  useEffect(() => {
    const checkUsername = async () => {
      if (profile?.username && profile.username !== userData?.username) {
        try {
          const response = await validateUsername(profile.username);
          const doesItExist = response?.data.exists;
          setIsUsernameValid(!doesItExist);
        } catch (error) {
          setIsUsernameValid(false);
        }
      } else {
        setIsUsernameValid(true);
      }
    };

    if (isEditing) {
      checkUsername();
    }
  }, [profile.username, userData?.username, isEditing]);

  const handleSave = async () => {
    try {
      setIsLoading(true);

      const updatedData = {};
      if (profile.name !== userData.name) updatedData.name = profile.name;
      if (profile.username !== userData.username) updatedData.username = profile.username;

      if (Object.keys(updatedData).length > 0) {
        const updatedProfile = await updateUserProfile(updatedData);
        setUserData(updatedProfile.data);
      }
      setIsEditing(false);
    } catch (error) {
      console.error('Error saving profile:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setProfile(userData);
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setIsLoading(true);
      try {
        const response = await uploadUserProfileImage(file);
        setProfile((prev) => ({ ...prev, profile_image: response.data.profile_image }));
        setUserData((prev) => ({ ...prev, profile_image: response.data.profile_image }));
      } catch (error) {
        console.error('Error uploading profile image:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const isSaveDisabled = !isUsernameValid || loading;

  if (loading) {
    return <div>
      <div className="hidden sm:block">
        <SideNavigation />
      </div>
      <div className={`min-h-screen ${darkTheme ? 'bg-[#181818] border-[#ffffff16] text-gray-300' : 'bg-[#fafafb] text-gray-800'} p-4 sm:p-6 lg:p-8`}>
        <ProfilePageSkeleton />
      </div>
    </div>
  }

  return (
    <Layout>
      <div className={`min-h-screen ${darkTheme ? 'bg-[#181818] border-[#ffffff16] text-gray-300' : 'bg-[#fafafb] text-gray-800'} p-4 sm:p-6 lg:p-8`}>
        <div className="hidden sm:block">
          <SideNavigation />
        </div>
        <div className="max-w-3xl mx-auto">
          <div className={`${darkTheme ? 'bg-[#101010]' : 'bg-white'} rounded-lg shadow p-6`}>
            <div className="flex justify-between items-center mb-6">
              <h1 className="text-2xl font-bold">Profile Settings</h1>
              <div className="flex gap-2">
                {isEditing ? (
                  <>
                    <button
                      onClick={handleCancel}
                      className={`flex items-center px-4 py-2 rounded-lg border ${darkTheme ? 'hover:bg-gray-700 border-[#ffffff16]' : 'hover:bg-gray-50'}`}
                    >
                      <X className="w-4 h-4 mr-2" />
                      <span>Cancel</span>
                    </button>
                    <button
                      onClick={handleSave}
                      disabled={isSaveDisabled}
                      className={`flex items-center px-4 py-2 rounded-lg ${isSaveDisabled
                        ? 'bg-gray-300 cursor-not-allowed'
                        : 'bg-blue-600 text-white hover:bg-blue-700'
                        }`}
                    >
                      <Save className="w-4 h-4 mr-2" />
                      <span>Save Changes</span>
                    </button>
                  </>
                ) : (
                  <button
                    onClick={() => setIsEditing(true)}
                    className={`flex items-center px-4 py-2 rounded-lg border ${darkTheme ? 'hover:bg-gray-700 border-[#404040]' : 'hover:bg-gray-50'}`}
                  >
                    <Edit2 className="w-4 h-4 mr-2" />
                    <span>Edit Profile</span>
                  </button>
                )}
              </div>
            </div>

            <div className="space-y-6">
              <div className="flex flex-col items-center space-y-4">
                <div className="relative">
                  <img
                    src={profile?.profile_image || '/logo.png'}
                    alt="Profile"
                    className="w-32 h-32 rounded-full object-cover border-2 border-gray-200"
                  />
                  {isEditing && <label className="absolute bottom-0 right-0 bg-blue-600 text-white p-2 rounded-full cursor-pointer hover:bg-blue-700">
                    <Camera className="w-4 h-4" />
                    <input
                      type="file"
                      className="hidden"
                      accept="image/*"
                      onChange={handleImageUpload}
                    />
                  </label>}
                </div>
              </div>

              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">Name</label>
                  <input
                    type="text"
                    value={profile?.name || ''}
                    onChange={(e) => setProfile((prev) => ({ ...prev, name: e.target.value }))}
                    disabled={!isEditing}
                    className={`mt-1 block w-full rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 ${darkTheme ? 'bg-[#303030] disabled:bg-[#202020] disabled:text-gray-300 border-[#ffffff16]' : 'disabled:bg-gray-50 disabled:text-gray-600'} p-2 border`}
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">Email</label>
                  <input
                    type="email"
                    value={profile?.email || ''}
                    disabled
                    className={`mt-1 block w-full rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 ${darkTheme ? 'bg-[#303030] disabled:bg-[#202020] disabled:text-gray-300 border-[#ffffff16]' : 'disabled:bg-gray-50 disabled:text-gray-600'} p-2 border`}
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">Username</label>
                  <input
                    type="text"
                    value={profile?.username || ''}
                    onChange={(e) => setProfile((prev) => ({ ...prev, username: e.target.value }))}
                    disabled={!isEditing}
                    className={`mt-1 block w-full rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 ${darkTheme ? 'bg-[#303030] disabled:bg-[#202020] disabled:text-gray-300 border-[#ffffff16]' : 'disabled:bg-gray-50 disabled:text-gray-600'} p-2 border`}
                  />
                  {!isUsernameValid && isEditing && (
                    <p className="mt-1 text-sm text-red-500">Username is already taken</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ProfilePage;
