import axiosInstance from '../interceptors/auth.interceptor.js';

export const createNote = async (channel_id, insight_id) => {
    try {
      const response = await axiosInstance.post(`/channel/${channel_id}/insight/${insight_id}/note`, {
        "content":""
      });
      return response.data;
    } catch (error) {
      console.error('Error creating channel:', error);
      return null;
    }
  };
  
export const updateNotes = async (note_id, content) => {
    try {
      const response = await axiosInstance.put(`/notes/${note_id}`, JSON.stringify({
        content
      }));
      return response.data;
    } catch (error) {
      console.error('Error creating channel:', error);
      return null;
    }
  };
export const addToNotes = async (channel_id, insight_id, content) => {
    try {
      const response = await axiosInstance.put(`/channel/${channel_id}/insight/${insight_id}/note`, JSON.stringify({
        content
      }));
      return response.data;
    } catch (error) {
      console.error('Error creating channel:', error);
      return null;
    }
  };
export const getNotesDetails = async (note_id) => {
    try {
      const response = await axiosInstance.get(`/notes/${note_id}`);
      return response.data;
    } catch (error) {
      console.error('Error creating channel:', error);
      return null;
    }
  };
