// import React, { useContext, useEffect, useState } from 'react';
// import { motion } from 'framer-motion';
// import { FaArrowRightLong, FaArrowLeftLong } from 'react-icons/fa6';
// import { Context } from '../../Context/Context';
// import { createCategories, getNewCategories } from '../../services/schedulerJobService';
// import { useNavigate } from 'react-router-dom';

// const OnboardingPage = () => {
//     const [currentSection, setCurrentSection] = useState(0);
//     const { darkTheme } = useContext(Context);
//     const [onboardingData, setOnboardingData] = useState([]);
//     const [selections, setSelections] = useState([]);
//     const navigate = useNavigate();

//     useEffect(() => {
//         fetchSections();
//     }, []);

//     const fetchSections = async () => {
//         try {
//             const response = await getNewCategories();
//             if (response?.data) {
//                 const interestsArray = Object.keys(response.data.interests).map((title) => ({
//                     title,
//                     options: response.data.interests[title],
//                 }));
//                 setOnboardingData(interestsArray);
//             }
//         } catch (error) {
//             console.error("Error getting categories:", error);
//         }
//     };
//     const handleCreate = async () => {
//         const interestsData = onboardingData.reduce((acc, section, index) => {
//             if (selections[index]) {
//                 acc[section.title] = [selections[index]];
//             }
//             return acc;
//         }, {});

//         const data = {
//             interests: interestsData
//         };
//         try {
//             const response = await createCategories(data);
//             if (response?.data) {
//                 navigate('/home');
//             }
//         } catch (error) {
//             console.log("Error creating categories:", error);
//         }
//     };


//     const handleSelection = (option) => {
//         const newSelections = [...selections];
//         newSelections[currentSection] = option;
//         setSelections(newSelections);
//     };

//     const handleNext = () => {
//         if (currentSection < onboardingData.length - 1) {
//             setCurrentSection(currentSection + 1);
//         }
//     };

//     const handleBack = () => {
//         if (currentSection > 0) {
//             setCurrentSection(currentSection - 1);
//         }
//     };

//     const atLeastTwoSelected = selections.filter((selection) => selection !== '').length >= 2;

//     return (
//         <div
//             className={`h-screen w-screen flex flex-col items-center justify-center ${darkTheme ? "bg-[#202020]" : "bg-gradient-to-r from-gray-100 via-gray-200 to-gray-300"
//                 }`}
//         >
//             <div
//                 className={`sm:max-w-7xl 3xl:max-w-[70%] w-full px-24 py-6 ${darkTheme
//                     ? "bg-[#181818] shadow-[0_4px_10px_rgba(150,150,150,0.15)]"
//                     : "bg-white"
//                     } rounded-lg shadow-lg h-[40%] relative`}
//             >
//                 <div className="flex mb-4">
//                     {onboardingData.map((section, index) => (
//                         <motion.div
//                             className={`w-full h-3 cursor-pointer`}
//                             onClick={() => index < currentSection && setCurrentSection(index)}
//                             key={index}
//                         >
//                             <motion.div
//                                 className={`flex-1 h-2 mx-1 rounded-full cursor-pointer ${index < currentSection
//                                     ? 'bg-[#f15025]'
//                                     : index === currentSection
//                                         ? 'bg-[#f15025]'
//                                         : 'bg-gray-400'
//                                     }`}
//                                 transition={{ duration: 0.5 }}
//                             />
//                         </motion.div>
//                     ))}
//                 </div>

//                 {/* Back Button */}
//                 <button
//                     onClick={handleBack}
//                     disabled={currentSection === 0}
//                     className={`absolute left-2 top-1/2 transform -translate-y-1/2 flex items-center justify-center p-2 rounded-full h-10 w-10 ${currentSection === 0
//                         ? "text-gray-400 cursor-not-allowed"
//                         : `text-[#f15025]"}`
//                         }`}
//                 >
//                     <FaArrowLeftLong
//                         className={`${currentSection === 0 ?
//                             `${darkTheme ? "text-gray-300" : "text-gray-700"}`
//                             : `text-[#f15025]`
//                             }`}
//                     />
//                 </button>

//                 {/* Main Content */}
//                 {onboardingData.length > 0 ? (
//                     <motion.div
//                         key={currentSection}
//                         initial={{ opacity: 0, x: 50 }}
//                         animate={{ opacity: 1, x: 0 }}
//                         exit={{ opacity: 0, x: -50 }}
//                         transition={{ duration: 0.5 }}
//                     >
//                         <h2
//                             className={`text-2xl font-bold mb-6 ${darkTheme ? "text-gray-400" : "text-gray-800"
//                                 }`}
//                         >
//                             {onboardingData[currentSection]?.title}
//                         </h2>
//                         <div className="grid grid-cols-3 sm:grid-cols-4 gap-3 mb-6 py-4">
//                             {onboardingData[currentSection]?.options.map((option) => (
//                                 <button
//                                     key={option}
//                                     className={`py-3 px-4 border rounded-full text-sm font-medium transition-all ${selections[currentSection] === option
//                                         ? "bg-[#f15025] text-white"
//                                         : `${darkTheme
//                                             ? "text-gray-300 border-gray-700"
//                                             : "text-gray-700 border-gray-300"
//                                         }`
//                                         } hover:bg-[#f15025] hover:text-white`}
//                                     onClick={() => handleSelection(option)}
//                                 >
//                                     {option}
//                                 </button>
//                             ))}
//                         </div>
//                         <div className="flex justify-end mt-16">
//                             <button
//                                 onClick={() => handleCreate()}
//                                 disabled={!atLeastTwoSelected}
//                                 className={`flex items-center justify-center p-2 w-[180px] rounded-md border border-green-600 bg-green-500 text-white transition-all ${!atLeastTwoSelected
//                                     ? "opacity-50 cursor-not-allowed"
//                                     : "hover:bg-green-600"
//                                     }`}
//                             >
//                                 Create Workspace
//                             </button>
//                         </div>
//                     </motion.div>
//                 ) : (
//                     <div className="text-center text-gray-500">Loading...</div>
//                 )}

//                 {/* Next Button */}
//                 <button
//                     onClick={handleNext}
//                     disabled={currentSection === onboardingData.length - 1}
//                     className={`absolute right-2 top-1/2 transform -translate-y-1/2 flex items-center justify-center p-2 rounded-full h-10 w-10 ${currentSection === onboardingData.length - 1
//                         ? "text-gray-400 cursor-not-allowed"
//                         : `text-[#f15025]"}`
//                         }`}
//                 >
//                     <FaArrowRightLong
//                         className={`${currentSection === onboardingData.length - 1
//                             ? `${darkTheme ? "text-gray-300" : "text-gray-700"}`
//                             : ` text-[#f15025]`
//                             }`}
//                     />
//                 </button>
//             </div>
//         </div>
//     );
// };

// export default OnboardingPage;



import React, { useState, useContext, useEffect } from 'react';
import { motion } from "framer-motion";
import { MdOutlineArrowUpward } from 'react-icons/md';
import { Context } from '../../Context/Context';
import { ceateInsightOnboarding, uploadPdfOnboarding } from '../../services/insightService';
import { createSpacePrompt } from '../../services/promptServices';
import { useNavigate } from 'react-router-dom';
import { getUserProfile } from '../../services/userService';

const LoadingScreen = () => {
    const { darkTheme } = useContext(Context);
    return (
        <div
            className={`flex items-center justify-center h-screen ${darkTheme ? "bg-[#181818] text-zinc-200" : "bg-[#ffffff] text-zinc-800"} text-white`}
        >
            {/* <div
                className="absolute inset-0 z-0"
                style={{
                    backgroundImage: `url(${darkTheme ? "/dark-bg-screen.png" : "/light-bg-screen.png"})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    opacity: 0.2,
                }}
            /> */}

            <div className="text-center">
                {/* Animated Text */}
                <motion.h1
                    className="text-2xl font-bold mb-4"
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1, ease: "easeInOut" }}
                >
                    Getting your workspace ready
                </motion.h1>
                <motion.p
                    className="text-lg mb-8"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 1, duration: 1, ease: "easeInOut" }}
                >
                    Hang in tight
                </motion.p>

                {/* Loading Animation */}
                <motion.div
                    className="flex justify-center gap-2"
                    initial="hidden"
                    animate="visible"
                    variants={{
                        hidden: { opacity: 0 },
                        visible: {
                            opacity: 1,
                            transition: { delay: 2, staggerChildren: 0.3 },
                        },
                    }}
                >
                    {Array(3)
                        .fill("")
                        .map((_, index) => (
                            <motion.div
                                key={index}
                                className="w-4 h-4 bg-blue-500 rounded-full"
                                variants={{
                                    hidden: { y: 0 },
                                    visible: {
                                        y: [0, -10, 0],
                                        transition: {
                                            repeat: Infinity,
                                            duration: 1,
                                            delay: index * 0.2,
                                        },
                                    },
                                }}
                            />
                        ))}
                </motion.div>
            </div>
        </div>
    );
};

const OnboardingPage = () => {
    const [selectedOption, setSelectedOption] = useState("Paste a link");
    const [promptText, setPromptText] = useState('');
    const [uploadedFile, setUploadedFile] = useState(null);
    const { darkTheme, setUserData, userData } = useContext(Context);
    const [placeHolderValue, setPlaceHolderValue] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const examplePrompts = [
        'I am researching on semiconductors, especially Graphcore, Nvidia, and Groq.',
        'Give me the latest on Perplexity and OpenAI from CNBC.'
    ];
    const fetchData = async () => {
        try {
            const user_data = await getUserProfile();
            if (user_data?.data) {
                setUserData(user_data.data);
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);
    const [currentPromptIndex, setCurrentPromptIndex] = useState(0);
    const [isTyping, setIsTyping] = useState(true);

    useEffect(() => {
        let currentText = '';
        let currentIndex = 0;
        let timeout;

        const typeText = () => {
            if (isTyping) {
                const targetText = examplePrompts[currentPromptIndex];
                if (currentIndex < targetText.length) {
                    currentText = targetText.slice(0, currentIndex + 1);
                    setPlaceHolderValue(currentText);
                    currentIndex++;
                    timeout = setTimeout(typeText, 50); // Typing speed
                } else {
                    setTimeout(() => setIsTyping(false), 1000); // Pause at the end
                }
            } else {
                if (currentIndex > 0) {
                    currentText = examplePrompts[currentPromptIndex].slice(0, currentIndex - 1);
                    setPlaceHolderValue(currentText);
                    currentIndex--;
                    timeout = setTimeout(typeText, 30); // Deletion speed (faster)
                } else {
                    setCurrentPromptIndex((prev) => (prev + 1) % examplePrompts.length);
                    setIsTyping(true);
                }
            }
        };

        timeout = setTimeout(typeText, 500);

        return () => clearTimeout(timeout);
    }, [currentPromptIndex, isTyping]);



    const handleSubmit = () => {
        if (selectedOption === "Upload your doc" && uploadedFile) {
            console.log('Submit:', { option: selectedOption, file: uploadedFile.name });
            setUploadedFile(null);
        } else if (promptText.trim()) {
            console.log('Submit:', { option: selectedOption, prompt: promptText });
            setPromptText('');
        }
    };

    const handleFileUpload = (e) => {
        setUploadedFile(e.target.files[0]);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        if (e.dataTransfer.files.length) {
            setUploadedFile(e.dataTransfer.files[0]);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && e.shiftKey && selectedOption !== "Upload your doc") {
            e.preventDefault();
            handleSubmit();
        }
    };
    function checkSource(url) {
        const check =
            url.includes("youtube") ||
            url.includes("youtu") ||
            url.includes("youtu.be");
        return check ? "youtube" : "web";
    }

    const makeDummyAPICall = async () => {
        // Dummy API calls based on selectedOption
        if (selectedOption === "Paste a link") {
            setLoading(true);
            const sourceType = checkSource(promptText);
            const response = await ceateInsightOnboarding(
                sourceType,
                promptText
            );
            if (response?.data) {
                navigate('/home');
                setLoading(false)
            }
            if (!response || response?.data.success === false) {
                setLoading(false);
                throw new Error("Failed to create insight.");
            }
        }
        else if (selectedOption === "Give a topic") {
            if (promptText.trim() === '') return;
            if (promptText.trim()) {
                setLoading(true);
                const response = await createSpacePrompt(promptText.trim());
                if (response?.data) {
                    setLoading(false);
                    navigate('/home');
                }
            }
        } else if (selectedOption === "Upload your doc" && uploadedFile) {
            setLoading(true);
            const response = await uploadPdfOnboarding(uploadedFile);
            if (response?.data) {
                const sourceType = 'pdf'
                const response2 = await ceateInsightOnboarding(
                    sourceType,
                    response.data.file_url
                );
                if (response2?.data) {
                    setLoading(false);
                    navigate('/home');
                }
            }
        }
    };
    if (loading) {
        return <LoadingScreen />
    }
    return (
        <div className={`w-full h-screen flex justify-center items-center ${darkTheme ? "bg-[#202020]" : "bg-[#fff]"}`}>
            {/* <div
                className="absolute inset-0 z-0"
                style={{
                    backgroundImage: `url(${darkTheme ? "/dark-bg-screen.png" : "/light-bg-screen.png"})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    opacity: 0.2,
                }}
            /> */}
            <div className={`max-w-4xl w-full p-10 border rounded-2xl ${darkTheme ? "border-[#ffffff16]" : "border-zinc-300"}`}>
                <div className='flex flex-row text-center justify-center items-center'>
                    <img src="/logo1.png" alt="" className={`w-[65px] h-[45px] mb-8 ${darkTheme ? "" : "filter invert"}`} />

                    <h1 className={`text-2xl mb-8 text-center ${darkTheme ? "text-zinc-200" : "text-zinc-800"}`}>
                        Hi {userData?.name}, start your research...
                    </h1>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                    {["Paste a link", "Give a topic", "Upload your doc"].map((option) => (
                        <div
                            key={option}
                            onClick={() => {
                                setSelectedOption(option);
                                setPromptText('');
                                setUploadedFile(null);
                            }}
                            className={`
                                p-6 rounded-lg cursor-pointer text-center shadow-md transition-all
                                ${darkTheme ? "bg-[#181818] text-zinc-200" : "bg-[#ffffff] text-zinc-800"}
                                ${selectedOption === option ? "ring-2 ring-[#f15025]" : ""}
                            `}
                        >
                            {option}
                        </div>
                    ))}
                </div>

                <div
                    className={`
                        mt-8 p-4 rounded-xl transition-all duration-300
                        ${darkTheme ? "bg-[#181818] border-[#ffffff16]" : "bg-[#fafbfa] border-zinc-200"}
                    `}
                >
                    {selectedOption === "Upload your doc" ? (
                        <div
                            className={`
                                w-full min-h-[108px] border-dashed border-2 flex items-center justify-center cursor-pointer
                                ${darkTheme ? "border-zinc-500 text-zinc-200" : "border-zinc-300 text-zinc-800"}
                            `}
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}
                            onClick={() => document.getElementById('fileInput').click()}
                        >
                            {uploadedFile ? (
                                <p>{uploadedFile.name}</p>
                            ) : (
                                <p>Drag and drop a file here or click to upload
                                    <p className="text-left text-[12px] text-gray-500">
                                        (Only PDF files are allowed. Max size: 10MB, Max pages: 30.)
                                    </p>
                                </p>
                            )}
                            <input
                                id="fileInput"
                                type="file"
                                className="hidden"
                                onChange={handleFileUpload}
                            />
                        </div>
                    ) : (
                        <textarea
                            placeholder={`${selectedOption === "Paste a link" ? "Paste a URL here to get quick insights (ex: Youtube Podcast or a blog article)" : placeHolderValue}`}
                            className={`
                                w-full bg-transparent border-none outline-none resize-none min-h-[100px] text-base p-2
                                ${darkTheme ? "text-zinc-200 placeholder-zinc-500" : "text-zinc-800 placeholder-zinc-400"}
                            `}
                            value={promptText}
                            onChange={(e) => setPromptText(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                    )}

                    <div className="flex justify-end pt-2">
                        <button
                            onClick={() => {
                                handleSubmit();
                                makeDummyAPICall();
                            }}
                            className={`p-2 text-white rounded-full transition-all
                                ${(selectedOption === "Upload your doc" && uploadedFile) || promptText.length > 0
                                    ? "bg-[#f15025] hover:bg-[#e1481e]"
                                    : "bg-[#404040]"
                                }
                            `}
                        >
                            <motion.div
                                animate={{ rotate: (uploadedFile || promptText.length > 0) ? 90 : 0 }}
                                transition={{ duration: 0.5, ease: "easeInOut" }}
                            >
                                <MdOutlineArrowUpward className="h-5 w-5" />
                            </motion.div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OnboardingPage;
