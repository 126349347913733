import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Home from "./pages/home/Home";
import { Provider } from './Context/Context';
import './App.css';
import Login from "./pages/login/Login";
import ProtectedRoute from "./ProtectedRoute.js";
import LandingPage from "./pages/landingPage/landingPage.jsx";
import Dashboard from "./pages/dashboard/Dashboard.jsx";
import GoogleAuthCallback from "./pages/googleAuthCallback/GoogleAuthCallback.jsx";
import SchedulerPage from "./pages/schedulerPage/SchedulerPage.jsx";
import OnboardingWrapper from "./pages/onboardingPage/OnBoardWrapper.jsx";
import OnboardingPage from "./pages/onboardingPage/OnboardingPage.jsx";
import ProfilePage from "./pages/ProfilePage/ProfilePage.jsx";
import ScreenShare from "./pages/shareScreen/ShareScreen.jsx";
const PublicRoute = ({ children }) => {
	const userEmail = localStorage.getItem('user_email');
	const accessToken = localStorage.getItem('access_token');

	if (userEmail && accessToken) {
		return <Navigate to="/home" replace />;
	}

	return children;
};

function App() {
	return (
		<Provider>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={
						<PublicRoute>
							<LandingPage />
						</PublicRoute>
					} />
					<Route path="/login" element={
						<PublicRoute>
							<Login />
						</PublicRoute>
					} />
					<Route path="/auth/google/callback" element={<GoogleAuthCallback />} />
					<Route path="/home/sp/:spaceId/ch/:channelId/in/:insightId/" element={
						<ProtectedRoute>
							<Home />
						</ProtectedRoute>
					} />
					<Route path="/home/sp/:spaceId/ch/:channelId/" element={
						<ProtectedRoute>
							<Home />
						</ProtectedRoute>
					} />
					<Route path="/home" element={
						<ProtectedRoute>
							<Home />
						</ProtectedRoute>
					} />
					<Route path="/profile" element={
						<ProtectedRoute>
							<ProfilePage />
						</ProtectedRoute>
					} />
					<Route path="/dashboard" element={
						<ProtectedRoute>
							<Dashboard />
						</ProtectedRoute>
					} />
					<Route path="/job-details" element={
						<ProtectedRoute>
							<SchedulerPage />
						</ProtectedRoute>
					} />
					<Route path="/onboarding" element={
						<ProtectedRoute>
							<OnboardingPage />
						</ProtectedRoute>
					} />
					<Route path="/history/:insightId" element={
						<ProtectedRoute>
							<ScreenShare />
						</ProtectedRoute>
					} />
					<Route path="/history" element={
						<ProtectedRoute>
							<ScreenShare />
						</ProtectedRoute>
					} />
					<Route path="/user/:user_name/in/:insightId" element={
						<ProtectedRoute>
							<ScreenShare />
						</ProtectedRoute>
					} />
					<Route path="/share/public/:insightId/" element={
						<ScreenShare />
					} />
				</Routes>
			</BrowserRouter>
		</Provider>
	);
}

export default App;
