import React, { useState, useEffect, useContext } from "react";
import { motion, AnimatePresence } from "framer-motion"; // Framer Motion
import { format } from "date-fns";
import { IoChatbubblesSharp } from "react-icons/io5";
import { PiNotepadFill } from "react-icons/pi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Context } from "../../Context/Context";
import Layout from "../Layout_Fit";
import { FaCircleXmark } from "react-icons/fa6";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css'
import ReactMarkdown from 'react-markdown';
import { MdOutlineArrowUpward } from "react-icons/md";
import { getPublicInsightDetails } from "../../services/publicAPIs";
import SkeletonInsights from "../../components/SkeletonInsights/SkeletonInsights";
import RightSidebar from "./RightNavigationDummy";
import LoginModal from "../../components/LoginModal/LoginModal";
import SidebarLogout from "./SidebarLogout";
import SharedInsightList from "./SharedInsightList";
import { addInsightToHistory, getHistoryInsights, getUserPublicInsights } from "../../services/insightService";
import { getHistoryUsers } from "../../services/userService";
import SideNavigationDummy from ".//SideNavigationDummy";
import SideNavigation from "../../components/SideNavigation/SideNavigation";
import AddSpaceModal from "../../components/AddSpaceModal/AddWorkSpcModal";
import SmartSpaceModal from "../../components/AddSmartSpace/AddSmartSpace";



const InsightDetails = ({ data, handleClickNotes, handleChatClick, darkTheme, overlay }) => {
  return (
    <div className={`px-6 overflow-y-auto custom-scrollbar scroll-container ${darkTheme ? 'dark-theme' : 'light-theme'} ${overlay === null ? "flex-grow flex" : ""} ${darkTheme ? "bg-[#181818] border-[#ffffff16]" : "bg-[#fafbfa] border-[#3d3d3d2d]"
      }`}>
      <div className="w-full p-4">
        <div className="flex flex-col sm:flex-row mb-3 sm:mb-6 py-4">
          {data.source_data.top_image && (
            <div className="sm:mx-[0px] mx-auto">
              <img
                src={data.source_data.top_image}
                alt="Top"
                className="w-[142px] h-[120px] p-1 mt-4 sm:mt-0 sm:w-32 sm:h-28 sm:p-1 lg:w-56 lg:p-3 lg:h-40 rounded-md shadow-md"
              />

            </div>
          )}
          <div className={`flex flex-col py-4 px-4 sm:mx-[0px] mx-auto`}>
            <div className="flex items-center space-x-2 sm:p-1 sm:py-0 py-1">
              <a
                href={data.url}
                target="_blank"
                rel="noopener noreferrer"
                className={`text-[20px] ${darkTheme
                  ? "text-[#ffffffa3]"
                  : "text-[#1a1a1aa3]"
                  }`}
              >
                <h2
                  className={`text-[17px] sm:text-[18px] lg:text-[24px] font-bold ${darkTheme
                    ? "text-white"
                    : "text-black"
                    } hover:text-blue-500 hover:underline transition-colors`}
                >
                  {data.source_data.title}
                </h2>
              </a>
            </div>
            <p
              className={`mt-1 inline-flex sm:mx-[0px] mx-auto sm:mt-2 sm:p-1 text-[12px] ${darkTheme
                ? "text-[#ffffffa3]"
                : "text-[#1a1a1aa3]"
                }`}
            >
              <img
                src={data.source_data.logo}
                alt="Example"
                loading="lazy"
                className="w-4 h-4 mr-2"
                onError={(e) => {
                  e.currentTarget.onerror = null;
                  e.currentTarget.src = "/logo.png";
                }}
              />
              {data.source_data.published_date
                ? format(
                  new Date(data.source_data.published_date),
                  "MMMM dd, yyyy"
                )
                : format(new Date(data.created_at), "MMMM dd, yyyy")}
            </p>            <div className="flex space-x-4 mt-4">
              <motion.button
                onClick={handleChatClick}
                className={`flex items-center space-x-2 px-4 py-2 rounded-full border border-orange-500 ${darkTheme ? "text-white border-[#ffffff16] hover:bg-[#202020]" : "text-black border-[#3d3d3d2d] hover:bg-orange-100"} transition `}
              >
                <span>Chat</span>
                <IoChatbubblesSharp className="text-green-700" />
              </motion.button>

              <motion.button
                onClick={handleClickNotes}
                className={`flex items-center space-x-2 px-4 py-2 rounded-full border border-orange-500 ${darkTheme ? "text-white border-[#ffffff16] hover:bg-[#202020]" : "text-black border-[#3d3d3d2d] hover:bg-orange-100"} transition `}
              >
                <span>Notes</span>
                <PiNotepadFill className="text-yellow-600" />
              </motion.button>
            </div>

          </div>
        </div>

        <div
          className={`sm:py-3 border-b w-full md:px-[1px] lg:px-1 ${darkTheme
            ? "text-[#ffffffa3] border-[#2a2929]"
            : "text-[#1a1a1aa3] border-opacity-20 font-semibold"
            } sm:pr-[50px] 3xl:pr-[70px]`}>
          {data.summary?.analysis?.categories.map((category, index) => (
            <div key={index} className="mb-4">
              <h3 className={`text-[17px] md:text-[16px] lg:text-[20px] capitalize ${darkTheme ? "text-white" : "text-black"}`}>
                {category}
              </h3>
              <ul className={`mt-2 list-disc rounded-md px-1 ${darkTheme
                ? "text-[#ffffffa3]"
                : "text-[#1a1a1aa3]"
                }`}
              >
                {data.summary?.analysis?.insights
                  ?.find((insight) => insight.category === category)
                  ?.points.map((point, idx) => (
                    <li key={idx} className={`sm:mx-2 text-[16px] sm:p-[5px] lg:p-2 font-normal text-justify sm:text-[13px] lg:text-[16px] ${darkTheme
                      ? "text-white"
                      : "text-black"
                      }`}
                    >{point}</li>
                  ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const ScreenShare = () => {
  const { user_name, insightId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [overlay, setOverlay] = useState(null);
  const { darkTheme, setSelectedSectionDesktop } = useContext(Context);
  const [responseData, setResponseData] = useState(null);
  const handleChatClick = () => setOverlay("chat");
  const handleClickNotes = () => setOverlay("notes");
  const [isInsightLoading, setInsightLoading] = useState(false);
  const [isListLoading, setListLoading] = useState(false);
  const [insightList, setInsightList] = useState([]);
  const closeOverlay = () => setOverlay(null);
  const isLoggedIn = localStorage.getItem('access_token') !== null;
  const [peopleList, setPeopleList] = useState([]);
  const [isHistorySelected, setHistorySelected] = useState(false);
  useEffect(() => {
    const fetchallDetails = async () => {
      try {
        const response = await getPublicInsightDetails(insightId);
        if (response?.data) {
          setResponseData(response?.data);
          setInsightLoading(true);
        }
      } catch (error) {
        console.error("Error fetching insight details:", error);
        setResponseData("no_data")
        setInsightLoading(false);
      }
      finally {
        setInsightLoading(false);
      }
    };

    if (insightId) fetchallDetails();
    setSelectedSectionDesktop('History')
  }, [insightId]);

  const getRouteType = (pathname) => {
    if (pathname.startsWith('/history')) return 'history';
    if (pathname.startsWith('/user')) return 'user';
    if (pathname.startsWith('/share/public')) return 'public';
    return null;
  };


  const prevRouteType = React.useRef(null);
  useEffect(() => {
    const currentRouteType = getRouteType(location.pathname);

    const handleRouteChange = async () => {
      // Only proceed if the route type has changed
      if (currentRouteType !== prevRouteType.current) {
        prevRouteType.current = currentRouteType;

        if (currentRouteType === 'history') {
          setHistorySelected(true);
          if (isLoggedIn) {
            try {
              setListLoading(true);
              setInsightLoading(true);
              const response = await getHistoryInsights(insightId);
              const response2 = await getHistoryUsers();

              if (response2?.data) {
                setPeopleList(response2.data.users);
              }

              if (response?.data) {
                const insights = response.data.insights.map((insight) => ({
                  id: insight.insight_id,
                  logo: insight.source_data.logo,
                  source: insight.source,
                  title: insight.source_data.title,
                  read: insight.read,
                  image: insight.source_data.top_image,
                  description: insight.description,
                  url: insight.url,
                  time: insight.updated_at,
                }));

                insights.sort((a, b) => new Date(b.time) - new Date(a.time));

                if (!insightId) {
                  navigate(`/history/${insights[0].id}`, { replace: true });
                }

                setInsightList(insights);
              }
            } catch (error) {
              console.error("Error fetching insight details:", error);
            } finally {
              setListLoading(false);
              setInsightLoading(false);
            }
          }
        } else if (currentRouteType === 'user' && isLoggedIn) {
          setHistorySelected(false);
          try {
            setListLoading(true);
            setInsightLoading(true);
            const response = await getUserPublicInsights(user_name);
            const response2 = await getHistoryUsers();

            if (response2?.data) {
              setPeopleList(response2.data.users);
            }

            if (response?.data) {
              const insights = response.data.insights.map((insight) => ({
                id: insight.insight_id,
                logo: insight.source_data.logo,
                source: insight.source,
                title: insight.source_data.title,
                read: insight.read,
                image: insight.source_data.top_image,
                description: insight.description,
                url: insight.url,
                time: insight.created_at,
              }));

              insights.sort((a, b) => new Date(b.time) - new Date(a.time));
              setInsightList(insights);
            }
          } catch (error) {
            console.error("Error fetching user insights:", error);
          } finally {
            setListLoading(false);
            setInsightLoading(false);
          }
        } else if (currentRouteType === 'public' && isLoggedIn) {
          try {
            await addInsightToHistory(insightId);
            navigate(`/history/${insightId}`, { replace: true });
          } catch (error) {
            console.error("Error adding insight to history:", error);
          }
        }
      }
    };

    handleRouteChange();
  }, [location.pathname, isLoggedIn]);

  // useEffect(() => {
  //   const addToHistory = async () => {
  //     try {
  //       await addInsightToHistory(insightId);
  //       navigate(
  //         `/history/${insightId}`,
  //         { replace: true }
  //       );
  //     } catch (error) {
  //       console.error("Error fetching insight details:", error);
  //     }
  //     finally {
  //       setInsightLoading(false);
  //     }
  //   };
  //   const getAllInsights = async () => {
  //     try {
  //       setListLoading(true);
  //       setInsightLoading(true)
  //       const response = await getHistoryInsights(insightId);
  //       const response2 = await getHistoryUsers();
  //       if (response2?.data) {
  //         setPeopleList(response2.data.users);
  //       }
  //       if (response?.data) {
  //         const insights = response.data.insights.map((insight) => ({
  //           id: insight.insight_id,
  //           logo: insight.source_data.logo,
  //           source: insight.source,
  //           title: insight.source_data.title,
  //           read: insight.read,
  //           image: insight.source_data.top_image,
  //           description: insight.description,
  //           url: insight.url,
  //           time: insight.created_at,
  //         }));

  //         insights.sort((a, b) => new Date(b.time) - new Date(a.time));
  //         if (!insightId) {
  //           navigate(
  //             `/history/${insights[0].id}`,
  //             { replace: true }
  //           );
  //         }
  //         setInsightList(insights);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching insight details:", error);
  //     } finally{
  //       setListLoading(false);
  //       setInsightLoading(false)
  //     }
  //   };

  //   const getUserInsights = async () => {
  //     try {
  //       setListLoading(true);
  //       setInsightLoading(true)
  //       const response = await getUserPublicInsights(user_name);
  //       const response2 = await getHistoryUsers();
  //       if (response2?.data) {
  //         setPeopleList(response2.data.users);
  //       }
  //       if (response?.data) {
  //         const insights = response.data.insights.map((insight) => ({
  //           id: insight.insight_id,
  //           logo: insight.source_data.logo,
  //           source: insight.source,
  //           title: insight.source_data.title,
  //           read: insight.read,
  //           image: insight.source_data.top_image,
  //           description: insight.description,
  //           url: insight.url,
  //           time: insight.created_at,
  //         }));

  //         insights.sort((a, b) => new Date(b.time) - new Date(a.time));
  //         setInsightList(insights);
  //       }
  //     }
  //     catch (error) {
  //       throw error;
  //     } finally{
  //       setListLoading(false);
  //       setInsightLoading(false)
  //     }
  //   }
  //   if (location.pathname.includes('/history')) {
  //     setHistorySelected(true);
  //   }
  //   else {
  //     setHistorySelected(false);
  //   }
  //   if(isLoggedIn && location.pathname.includes('/user/')){
  //     getUserInsights();
  //   }
  //   if (isLoggedIn && location.pathname.includes('/history')) {
  //     getAllInsights();
  //   }
  //   if (isLoggedIn && location.pathname.includes('/share/public/')) addToHistory();
  // }, [location]);


  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ align: [] }],
      [{ color: [] }, { background: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ blockquote: true }, { code: true }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  const formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "align",
    "color",
    "background",
    "list",
    "bullet",
    "indent",
    "header",
    "blockquote",
    "code-block",
    "link",
    "image",
    "video",
  ];

  const customStyles = `
    /* Placeholder Styles */
    .ql-editor.ql-blank::before {
      color: ${darkTheme ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)'} !important;
    }

    .ql-container {
          ${darkTheme
      ? 'border-color: #333 !important; color: #fff !important;'
      : 'border-color: #e0e0e0 !important; color: #000 !important;'}
    }
    .ql-toolbar {
      ${darkTheme
      ? 'border-color: #333 !important; color: #fff !important;'
      : 'border-color: #e0e0e0 !important; color: #000 !important;'}
    }

    /* Toolbar Button Styles */
    .ql-toolbar .ql-picker,
    .ql-toolbar .ql-picker-label,
    .ql-toolbar button {
      ${darkTheme
      ? 'color: #c0c0c0 !important; fill: #c0c0c0 !important;'
      : 'color: #555 !important; fill: #555 !important;'}
    }

    /* Toolbar Hover and Active States */
    .ql-toolbar .ql-picker:hover,
    .ql-toolbar button:hover,
    .ql-toolbar .ql-picker-label:hover,
    .ql-toolbar button.ql-active,
    .ql-toolbar .ql-picker-label.ql-active {
      ${darkTheme
      ? 'color: #fff !important; background-color: #404040 !important;'
      : 'color: #000 !important; background-color: #e0e0e0 !important;'}
    }

    .ql-picker-options .ql-picker-item:hover {
      ${darkTheme
      ? 'background-color: #404040 !important;'
      : 'background-color: #f1f3f4 !important;'}}`;


  const transformConversationsToMessages = (chat) => {
    if (!chat || !chat.conversations) return [];
    return chat.conversations.flatMap((conv) => [
      { sender: "user", text: conv.question },
      { sender: "bot", text: conv.answer.replace(/<question>.*?<\/question>/, "") },
    ]);
  };

  const ChatOverlay = () => {
    const messages = transformConversationsToMessages(responseData?.chat || null);
    const [showLoginModal, setShowLoginModal] = useState(false);

    const handleOpenLoginModal = () => {
      setShowLoginModal(true);
    };

    return (
      <div
        style={{ height: "calc(100vh - 42px)" }}

        className={`flex flex-col w-screen  ${darkTheme ? "bg-[#181818] text-white" : "bg-[#fafbfa] text-black"
          }`}
      >
        <div className={`flex justify-left border-b ${darkTheme ? "border-[#ffffff16]" : "border-[#00000016]"}`}>
          <div className="p-2 mx-2 min-h-[42px] sm:mx-[0px] mx-auto">
            <div>Chat</div>
          </div>
        </div>
        <div className={`relative sm:flex-grow overflow-y-auto sm:ml-[-42px] px-4 sm:px-16 2xl:px-24 3xll:px-48 scroll-container custom-scrollbar sm:pr-[50px] 3xl:pr-[70px] ${darkTheme ? 'dark-theme' : 'light-theme'}`} style={{ zIndex: 1 }}
        >
          <AnimatePresence>

            {messages?.map((message, index) => (
              <div
                key={index}
                className={`mb-4 py-3 rounded-full ${index === 0 ? "mt-8" : ""} ${(index === messages.length - 1) ? "mb-[90px]" : ""} ${message.sender === 'user' ? 'text-right' : 'text-left'}`}
              >
                <div
                  className={`inline-flex px-4 py-1 rounded-xl leading-loose ${message.sender === 'user' ? (darkTheme ? 'bg-[#353535] text-white' : 'bg-gray-200 text-black') : darkTheme ? '' : ''}`}
                >
                  {message.sender !== 'user' && <img src='/logo1.png' alt="gpt" className={`w-4 h-4 inline mr-2 mb-1 mt-[10px] ${darkTheme ? "" : "filter invert"}`} />}
                  <ReactMarkdown
                    className={`leading-loose text-left sm:text-[16px] 3xll:text-[18px] ${darkTheme ? "text-gray-300" : ""}`}
                  >
                    {message.text}</ReactMarkdown>
                </div>
              </div>
            ))}
          </AnimatePresence>
        </div>
        <div className={`hidden sm:flex justify-center sm:py-4 3xl:py-6 px-8 ${darkTheme ? '' : ''}`}>
          <div className={`flex sm:w-[90%] 3xl:w-[65%] 3xll:w-[70%] ${darkTheme ? "bg-[#323232] shadow-[0_0_3px_1px_rgba(59,140,246,0.75),0_0_1px_1px_rgba(59,130,246,0.5),0_0_1px_1px_rgba(59,130,246,0.25)]" : "bg-white shadow-[0_0_3px_1px_rgba(59,130,246,0.75),0_0_1px_1px_rgba(59,130,246,0.5),0_0_1px_1px_rgba(59,130,246,0.25)]"} pl-4 px-2 rounded-full py-1`}>
            <textarea
              type="text"
              className={`flex-grow sm:py-1 3xl:py-4 px-2 rounded-xl justify-center items-center focus:outline-none ${darkTheme
                ? "bg-[#323232] text-white placeholder-gray-300 border-[#3d4144]"
                : "bg-[#fbfbfa] text-black placeholder-[#acacad] border-[#c7c7c7]"
                }`}
              onKeyDown={(e) => {
                handleOpenLoginModal()
              }}

              placeholder="Chat with the Insight..."
              rows={1}
              style={{
                "scrollbar-width": "none"
              }}
            />
            <button
              className={`sm:p-2 3xll:p-4 text-white rounded-full bg-[#404040]`}
            >
              <MdOutlineArrowUpward className="h-6 w-6" />
            </button>
          </div>
        </div>
        <LoginModal isOpen={showLoginModal} handleClose={() => setShowLoginModal(false)} />
      </div>
    );
  };
  // Notes Overlay
  const NotesOverlay = () => (
    <div
      style={{ height: "calc(100vh - 42px)" }}
      className={`w-screen mx-auto transition-all duration-200 ${darkTheme ? "bg-[#181818] border-[#ffffff16] text-white" : "bg-[#fafbfa] text-gray-900"}`}
    >
      <style>{customStyles}</style>
      <div
        className={`flex justify-between border-b ${darkTheme ? "border-[#ffffff16]" : "border-[#00000016]"
          }`}
      >
        <div className="flex p-2 mx-2 min-h-[42px] sm:mx-[0px] mx-auto"><div>Notes</div></div>
      </div>
      <div className="pl-0 pb-4 sm:pr-[50px] 3xl:pr-[70px] h-screen overflow-hidden">
        <div className="h-full">
          <ReactQuill
            theme="snow"
            value={responseData?.notes === null ? "" : responseData?.notes.content}
            readOnly={true}
            modules={modules}
            formats={formats}
            className={`h-full !border-none ${darkTheme ? "!text-white" : "!text-black"
              }`}
            placeholder="Write your note here..."
          />
        </div>
      </div>
    </div>
  );


  const OverlayLayout = () => (
    <motion.div
      className={`fixed sm:ml-[115px] top-[42px] inset-0 sm:bg-black bg-opacity-50 z-20 flex justify-end border ${darkTheme ? "bg-[#202020] border-[#303030] shadow-left-dark" : "bg-[#F6F5F3] shadow-left-light"}`}
    >
      <div className="bg-white w-full h-auto flex relative">
        <div className={`h-full hidden sm:block overflow-auto hide-scrollbar w-1/2 sm:w-[500px] xl:w-[650px] xll:w-[700px] 2xl:w-[710px] 2xll:w-[850px] 3xl:w-[900px] 3xll:w-[1000px] 3xlll:w-[1100px] 4xl:w-[1180px] flex-shrink-0 z-[10] border-r ${darkTheme ? "bg-[#181818] border-[#222222]" : "bg-[#F6F5F3] "}`}>
          <InsightDetails data={responseData} handleClickNotes={handleClickNotes} handleChatClick={handleChatClick} darkTheme={darkTheme} />
        </div>

        <div
          style={{ height: "calc(100vh - 42px)" }}
          className="flex flex-grow overflow-hidden z-20"
        >
          <button
            onClick={closeOverlay}
            className="absolute top-3 left-4 sm:top-4 sm:left-4 text-black font-bold text-lg"
          >
            <FaCircleXmark
              className={`w-5 h-5 rounded-full cursor-pointer ${darkTheme ? "bg-black text-white" : "text-black bg-white"
                }`}
            />
          </button>
          {overlay === "chat" ? <ChatOverlay darkTheme={darkTheme} /> : <NotesOverlay />}
        </div>
      </div>
      <div className="hidden sm:block">
      <RightSidebar />
      </div>
    </motion.div>
  );

  return (
    <Layout>
      <AddSpaceModal />
      <SmartSpaceModal/>
      <div
        style={{ height: "calc(100vh - 42px)" }}
        className={`mt-[42px] border-t ${darkTheme ? "bg-[#202020] border-[#ffffff16]" : "bg-[#fafbfa] border-[#3d3d3d2d] text-gray-900"}`}>
        <div className="hidden sm:block z-[16]">
          <SidebarLogout
            peopleList={peopleList}
            insight_id={insightId}
            user_name={user_name}
            isHistorySelected={isHistorySelected}
            setHistorySelected={setHistorySelected}
            setInsightList={setInsightList}
          />
          <RightSidebar
            handleClickNotes={handleClickNotes}
            handleChatClick={handleChatClick}
          />

        </div>
        {
          isLoggedIn && (
            <div
              style={{ height: "calc(100vh - 42px)" }}
              className="flex flex-row overflow-hidden"
            >
              <div
                className={`hidden sm:block sm:ml-[230px] md:ml-[238px] lg:ml-[275px] xl:ml-[275px] 3xl:ml-[308px] sm:min-h-screen lg:w-[350px] xl:w-[400px] 2xl:w-[450px] border-t ${darkTheme ? "border-[#ffffff16]" : "border-[#3d3d3d2d]"}`}
              >
                <SharedInsightList insightList={insightList}
                  insightId={insightId}
                  setResponseData={setResponseData}
                  setInsightLoading={setInsightLoading}
                  isListLoading={isListLoading}
                  setInsightList={setInsightList}
                  location={location}
                  user_name={user_name}
                />
              </div>
              <div
                className={`scroll-container custom-scrollbar border-t sm:pr-[50px] 3xl:pr-[70px] ${darkTheme ? "border-[#ffffff16]" : "border-[#3d3d3d2d]"}`}
              >
                {responseData && !isInsightLoading && (
                  <InsightDetails
                    data={responseData}
                    handleClickNotes={handleClickNotes}
                    handleChatClick={handleChatClick}
                    darkTheme={darkTheme}
                    overlay={overlay}
                  />
                )}
                {isInsightLoading && (
                  <div className={`w-screen overflow-y-auto custom-scrollbar scroll-container ${darkTheme ? 'dark-theme' : 'light-theme'} ${overlay === null ? "flex-grow flex" : ""} ${darkTheme ? "bg-[#181818] border-[#ffffff16]" : "bg-[#fafbfa] border-[#3d3d3d2d]"}`}>
                    <SkeletonInsights />
                  </div>
                )}
              </div>
            </div>)
        }
        {
          !isLoggedIn && (
            <div
              style={{ height: "calc(100vh - 42px)" }}
              className={`sm:ml-[230px] md:ml-[238px] lg:ml-[275px] xl:ml-[265px] 3xl:ml-[308px] scroll-container custom-scrollbar border-t ${darkTheme ? "border-[#ffffff16]" : "border-[#3d3d3d2d]"}`}
            >
              {responseData && (
                <InsightDetails
                  data={responseData}
                  handleClickNotes={handleClickNotes}
                  handleChatClick={handleChatClick}
                  darkTheme={darkTheme}
                  overlay={overlay}
                />
              )}

              {isInsightLoading && (
                <div>
                  <SkeletonInsights />
                </div>
              )}
            </div>)
        }
        {overlay && (
          <motion.div
            className={`fixed inset-0 z-20 flex justify-end ${darkTheme ? "bg-[#202020]" : "bg-white"
              } bg-opacity-[50%]`}
          >
            <OverlayLayout />
          </motion.div>
        )}
      </div>
    </Layout>
  );
};

export default ScreenShare;
