import axiosInstance from '../interceptors/auth.interceptor.js';

export const getAuthToken = async (username, password) => {
    try {
        const response = await axiosInstance.post('/auth/token', JSON.stringify({ username, password}));
        const { access_token } = response.data;
        localStorage.setItem('accessToken', access_token);
        return access_token;
    } catch (error) {
        console.error('Error fetching auth token:', error);
        throw error; 
    }
};

export const getUserProfile = async () => {
    try {
        const response = await axiosInstance.get('/user/profile');
        return response.data;
    } catch (error) {
        console.error('Error fetching user profile:', error);
        throw error; 
    }
};

export const getHistoryUsers = async () => {
    try {
        const response = await axiosInstance.get('/user/history/people');
        return response.data;
    } catch (error) {
        console.error('Error fetching user profile:', error);
        throw error; 
    }
};
export const updateUserProfile = async (updatedUserData) => {
    try {
        const response = await axiosInstance.put('/user/profile',updatedUserData);
        return response.data;
    } catch (error) {
        console.error('Error fetching user profile:', error);
        throw error;
    }
};
export const validateUsername = async (username) => {
    try {
        const response = await axiosInstance.get(`/user/username/${username}/check`);
        return response.data;
    } catch (error) {
        console.error('Error fetching user profile:', error);
        throw error; 
    }
};

export const createUser = async (username, name, email,password) => {
    try {
        const response = await axiosInstance.post('/auth/signup', JSON.stringify({ username, name, email, password }));
        const { access_token } = response.data;
        localStorage.setItem('accessToken', access_token);
        return access_token;
    } catch (error) {
        console.error('Error fetching auth token:', error);
        throw error; // Rethrow or handle the error as needed
    }
}
export const uploadUserProfileImage = async (file) => {
    try {
        const formData = new FormData();
        formData.append('file', file);

        const response = await axiosInstance.post('/user/profile/imageUpload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data; // Assuming the server returns a success message or URL of the uploaded image
    } catch (error) {
        console.error('Error uploading profile image:', error);
        throw error; // Rethrow or handle the error as needed
    }
};
