import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../Context/Context.jsx';
import { RiCloseFill } from 'react-icons/ri';
import { Dialog, DialogPanel, DialogTitle, Transition } from '@headlessui/react';
import toast from 'react-simple-toasts';
import { Fragment } from 'react';
import { AiOutlineLink } from "react-icons/ai";
import { changeInsightVisibility } from '../../services/insightService.js';

const ShareInsightModal = () => {
    const { showShareInsightModal, setShowShareInsightModal, darkTheme, pathIndex, insightDetails, setInsightDetails } = useContext(Context);
    const [isPublic, setIsPublic] = useState(insightDetails?.is_public.insight || false);
    const [isChatAndNotesPublic, setIsChatAndNotesPublic] = useState(insightDetails?.is_public.chat && insightDetails?.is_public.notes);

    useEffect(() =>{
        setIsChatAndNotesPublic(insightDetails?.is_public.chat && insightDetails?.is_public.notes)
        setIsPublic(insightDetails?.is_public.insight || false)
    })
    const handleClose = () => {
        setShowShareInsightModal(false);
    };

    const handleCopyLink = () => {
        navigator.clipboard.writeText(`https://tensorweave.com/share/public/${pathIndex.insight_id}`);
        toast("Link copied to clipboard!", {
            position: 'top-center',
            className: `${darkTheme ? "bg-black text-green-400" : "bg-white text-green-600"} py-3 px-4 rounded-lg shadow-lg flex items-center gap-2`,
            style: {
                border: "1px solid #4CAF50",
                fontSize: "0.95rem",
                fontWeight: "500",
            },
        });
    };

    const updateIsPublic = (newIsPublic) => {
        setInsightDetails((prevDetails) => ({
            ...prevDetails,
            is_public: { ...prevDetails.is_public, ...newIsPublic },
        }));
    };

    const togglePublicStatus = async () => {
        try {
            const newStatus = !isPublic;
            await changeInsightVisibility(insightDetails.insight_id, {
                insight: newStatus,
                chat: newStatus ? isChatAndNotesPublic : false,
                notes: newStatus ? isChatAndNotesPublic : false,
            });
            updateIsPublic({
                insight: newStatus,
                chat: isChatAndNotesPublic && newStatus,
                notes: isChatAndNotesPublic && newStatus,
            });
            setIsPublic(newStatus);
            if(newStatus === false) setIsChatAndNotesPublic(newStatus);

            toast(newStatus ? "Insight is now public" : "Insight is now private", {
                position: 'top-center',
                className: `${darkTheme ? "bg-black text-green-400" : "bg-white text-green-600"} py-3 px-4 rounded-lg shadow-lg flex items-center gap-2`,
                style: {
                    border: "1px solid #4CAF50",
                    fontSize: "0.95rem",
                    fontWeight: "500",
                },
            });
        } catch (error) {
            console.error("Error updating public status:", error);
        }
    };

    const toggleChatAndNotesVisibility = async () => {
        try {
            const newStatus = !isChatAndNotesPublic;
            setIsChatAndNotesPublic(newStatus);
            await changeInsightVisibility(insightDetails.insight_id, {
                insight: isPublic,
                chat: newStatus,
                notes: newStatus,
            });
            updateIsPublic({
                chat: newStatus,
                notes: newStatus,
            });
            toast(newStatus ? "Chat and Notes are now public" : "Chat and Notes are now private", {
                position: 'top-center',
                className: `${darkTheme ? "bg-black text-green-400" : "bg-white text-green-600"} py-3 px-4 rounded-lg shadow-lg flex items-center gap-2`,
                style: {
                    border: "1px solid #4CAF50",
                    fontSize: "0.95rem",
                    fontWeight: "500",
                },
            });
        } catch (error) {
            console.error("Error updating chat and notes visibility:", error);
        }
    };

    return (
        <Dialog open={showShareInsightModal} onClose={handleClose} className="relative z-[999]">
            <Transition appear show={showShareInsightModal} as={Fragment}>
                <div className={`fixed inset-0 ${darkTheme ? "bg-black" : "bg-white"} bg-opacity-55 backdrop-blur-md`} aria-hidden="true" />
                <div className="fixed inset-0 z-[999]">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <DialogPanel
                                className={`relative transform overflow-hidden rounded-2xl shadow-xl transition-all sm:my-8 sm:max-w-3xl w-full ${darkTheme
                                    ? 'bg-[#212529] text-white border-[1px] border-[#273037]'
                                    : 'bg-white text-black border-[1px] border-gray-200'
                                    }`}
                            >
                                {/* Modal Header */}
                                <div className="px-6 py-4 flex items-center justify-between">
                                    <DialogTitle
                                        className={`text-[17px] inter-bold leading-6 ${darkTheme ? 'text-white' : 'text-gray-900'}`}
                                    >
                                        Share This Insight
                                    </DialogTitle>
                                    <button
                                        type="button"
                                        onClick={handleClose}
                                        className="text-[#545657] hover:text-gray-500"
                                    >
                                        <RiCloseFill className="h-6 w-6" />
                                    </button>
                                </div>
                                <div className={`${darkTheme ? "border-[1px] border-[#273037]" : "border-[1px] border-gray-200"}`}></div>

                                <div className="mt-4 px-6 py-2">
                                    <div className="mt-4 flex items-center">
                                        <div className={`flex flex-row px-2 items-center w-full border rounded-md ${darkTheme ? 'bg-[#1A1B1A] border-[#3d4144] text-white' : 'bg-[#fbfbfa] border-[#c7c7c7] text-gray-900'}`}>
                                            <AiOutlineLink className='text-[22px] opacity-50' />
                                            <input
                                                readOnly
                                                value={`https://tensorweave.com/share/public/${pathIndex.insight_id}`}
                                                className={`w-full py-2 px-2 border-none bg-transparent focus:outline-none ${darkTheme ? 'text-white' : 'text-gray-900'}`}
                                            />
                                            <button
                                                onClick={handleCopyLink}
                                                className={` px-2 rounded-md ${darkTheme ? 'text-white' : 'text-gray-900'} focus:outline-none`}
                                            >
                                                Copy
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-4 px-6 flex items-center space-x-4">
                                    <input
                                        type="checkbox"
                                        id="chatAndNotesToggle"
                                        checked={isChatAndNotesPublic}
                                        onChange={toggleChatAndNotesVisibility}
                                        className="w-4 h-4"
                                    />
                                    <label
                                        htmlFor="chatAndNotesToggle"
                                        className={`text-[16px] font-semibold ${darkTheme ? 'text-white' : 'text-gray-900'}`}
                                    >
                                        Select this to make the chat and notes shareable as well
                                    </label>
                                </div>
                                {/* Public/Private Toggle Section */}
                                <div className="flex mt-4 px-6 py-2 justify-end">
                                    <button
                                        onClick={togglePublicStatus}
                                        className={`w-[20%] py-2 rounded-md text-center font-semibold ${isPublic
                                            ? darkTheme
                                                ? 'bg-red-600 text-white'
                                                : 'bg-red-500 text-white'
                                            : darkTheme
                                                ? 'bg-green-600 text-white'
                                                : 'bg-green-500 text-white'
                                            }`}
                                    >
                                        {isPublic ? 'Make Private' : 'Make Public'}
                                    </button>
                                </div>
                            </DialogPanel>
                        </Transition.Child>
                    </div>
                </div>
            </Transition>
        </Dialog>
    );
};

export default ShareInsightModal;
