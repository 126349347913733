// hooks/useFetchData.js
import { useContext, useEffect } from "react";
import { getUserProfile } from "../services/userService.js";
import { getChannelDetails } from "../services/channelService.js"
import { getOrganizationDetails } from "../services/orgService.js"
import { getInsightDetails } from "../services/insightService.js";
import { Context } from "../Context/Context";
import { icons } from "../components/icons.jsx";
import { HiHashtag } from "react-icons/hi";
import { getDashboardDetails } from "../services/dashboardService.js";
import { getAllThreadsByChannel, getThreadByChannelInsight } from "../services/chatService.js";

export const useFetchData = (setLoading, spaceId, channelId, insightId) => {
    const { setUserData, setKnowledgespace, setOrgData, setInsightDetails, setMessages, setAllThreadData, setSummaryInfo, setList, setChannelData, setPathIndex } = useContext(Context);

    const fetchData = async () => {
        try {
            setLoading(true);
            const user_data = await getUserProfile();
            if (!user_data?.data) return;
            setUserData(user_data.data);

            const org_id = user_data.data.org_roles?.[0]?.org_id;
            if (!org_id) return;

            const org_data_temp = await getOrganizationDetails(org_id);
            if (!org_data_temp?.data) return;

            const org_data = org_data_temp?.data;
            setOrgData(org_data);
            setPathIndex(prevPathIndex => ({
                ...prevPathIndex,
                org_id:org_id
            }));


            if (!org_data?.spaces?.[0]?.space_id) return;
            const knowledge_space_data = org_data.spaces?.map((space) => ({
                id: space.space_id,
                name: space.name,
                icon: space.icon || "🌏",
                channels: space.channels.map((channel) => ({
                    id: channel.channel_id,
                    name: channel.name,
                    icon: <HiHashtag />,
                    path: ["Knowledge Space >>", space.name, channel.name],
                    unread_insights_count : channel.unread_insights_count,
                })),
                path: ["Knowledge Spaces", space.name],
            }));
            
            setKnowledgespace(knowledge_space_data);
            if (!org_data?.spaces?.[0]?.channels?.[0]?.channel_id) return;
            const defaultChannelId = (!channelId ? org_data.spaces[0].channels[0].channel_id : channelId);

            const channel_data_response = await getChannelDetails(defaultChannelId);
            if (!channel_data_response?.data) return;
            const channel_data = channel_data_response.data;
            setChannelData(channel_data);
            // setPathIndex(prevPathIndex => ({
            //     ...prevPathIndex,
            //     channelId: defaultChannelId,
            //     space_id: spaceId || org_data.spaces[0].space_id,
            //     org_id:org_id
            // }));
            const insights = channel_data?.insights.map((insight) => ({
                id: insight.insight_id,
                logo: insight.source_data.logo,
                source: insight.source,
                title: insight.source_data.title,
                read : insight.read,
                image: insight.source_data.top_image,
                description: insight.description,
                url: insight.url,
                time: insight.created_at,
                is_public : insight.is_public,
                icon: icons.network,
            }));

            insights.sort((a, b) => new Date(b.time) - new Date(a.time));
            setList(insights);

            if (insights.length !== 0) {
                const defaultInsightId = (!insightId ? insights[0].id : insightId)
                const response_insight = await getInsightDetails(defaultChannelId, defaultInsightId);
                if(response_insight?.data){
                    setPathIndex(prevPathIndex => ({
                        channel_id: defaultChannelId,
                        space_id: spaceId || org_data.spaces[0].space_id,
                        org_id:org_id,
                        insight_id: response_insight?.data.insight_id
                    }));
                    setLoading(false);
                    let thread_data;
                    if(defaultChannelId && response_insight?.data.insight_id){
                        thread_data = await getThreadByChannelInsight(defaultChannelId, response_insight?.data.insight_id);
                    }
                    if(thread_data?.data){
                        setAllThreadData(thread_data.data)
                        const conversation_array = thread_data.data.map((item) => ({
                            thread_id: item.thread_id,
                            insight_id: item.insight_id,
                            conversations: item.conversations,
                          }));
                  
                          if (conversation_array && response_insight?.data.insight_id) {
                            const selectedThread = conversation_array.find(item => item.insight_id === response_insight?.data.insight_id);                  
                            if (selectedThread && selectedThread?.conversations?.length) {
                              const initialMessages = selectedThread.conversations.flatMap(conv => [
                                { text: conv.question, sender: 'user' },
                                { text: conv.answer, sender: 'bot' }
                              ]);
                              setMessages(initialMessages);
                            } else {
                              setMessages([]);
                            }
                          }
                    }
                }
                else{
                    setPathIndex(prevPathIndex => ({
                        ...prevPathIndex,
                        channel_id: defaultChannelId,
                        space_id: spaceId || org_data.spaces[0].space_id,
                        org_id:org_id,
                    }));

                }
                setInsightDetails(response_insight?.data);
                setSummaryInfo(channel_data_response.data);
            }

        } catch (error) {
            console.error("Error fetching data:", error);
        }
        finally{
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []); // Empty dependency array ensures it runs only once
};
