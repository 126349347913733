import React, { useState, useEffect, useContext, Suspense, useRef } from "react";
import { Context } from "../../../Context/Context";
import { format } from "date-fns";
import { Copy, BookMarked } from "lucide-react";
import { TbNotes } from "react-icons/tb";
import { IoChatbubblesSharp } from "react-icons/io5";
import { createChannelInsightThread, getThreadByChannelInsight } from "../../../services/chatService";
import { addToNotes, createNote } from "../../../services/notesService";
import { PiNotepadFill } from "react-icons/pi";
import { motion } from 'framer-motion';
import toast from "react-simple-toasts";
import { IoShareSocialSharp } from "react-icons/io5";
import { GoGlobe } from "react-icons/go";

const SkeletonInsights = React.lazy(() =>
    import("../../../components/SkeletonInsights/SkeletonInsights")
);

const Summary = ({ setLoadNotesTrigger, loadNotesTrigger, chatRef }) => {
    const { insightDetails, darkTheme, setShowChat, loadingInsight, pathIndex, setAllThreadData, setActionState, allThreadData, setInsightDetails, setNotesId, setShowShareInsightModal } = useContext(Context);
    const [showActions, setShowActions] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [selectedText, setSelectedText] = useState("");
    const summaryRef = useRef(null);
    const [hoveredItem, setHoveredItem] = useState("");

    useEffect(() => {
        const handleSelection = (e) => {
            // Check if the selection is within the summary component
            if ((summaryRef.current && summaryRef.current.contains(e.target)) || (chatRef.current && chatRef.current.contains(e.target))) {
                const selection = window.getSelection();
                const text = selection.toString().trim();

                if (text) {
                    const range = selection.getRangeAt(0);
                    const rect = range.getBoundingClientRect();

                    setSelectedText(text);
                    setPosition({
                        x: rect.x + rect.width / 2,
                        y: rect.y - 10,
                    });
                    setShowActions(true);
                } else {
                    setShowActions(false);
                    setSelectedText("");
                }
            } else {
                // If selection is outside the summary component, reset selection
                setShowActions(false);
                setSelectedText("");
            }
        };

        // Use mouseup and keyup to capture text selection
        document.addEventListener("mouseup", handleSelection);
        document.addEventListener("keyup", handleSelection);

        return () => {
            document.removeEventListener("mouseup", handleSelection);
            document.removeEventListener("keyup", handleSelection);
        };
    }, []);

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(selectedText);
            // Reset selection and actions after copying
            setShowActions(false);
            setSelectedText("");

            // Optional: Show a toast notification
            toast('Text copied!', {
                position: "top-center",
                theme: "info",
                className: `${darkTheme ? "bg-black text-white" : "bg-white text-black"} py-3 px-4 rounded-lg`,
                duration: 1500,
            });
        } catch {
            alert("Failed to copy text. Please try again.");
        }
    };
    const handleChatClick = async () => {
        setActionState(1);
        try {
            setShowChat(true);
            if (allThreadData?.length === 0) {
                const response = await createChannelInsightThread(pathIndex.channel_id, pathIndex.insight_id);

                if (response?.data && pathIndex.channel_id && pathIndex.insight_id) {
                    const thread_data = await getThreadByChannelInsight(pathIndex.channel_id, pathIndex.insight_id);

                    if (thread_data?.data) {
                        setAllThreadData(thread_data.data);
                    }
                }
            }
        } catch (error) {
            console.error("Error in handleChatClick:", error);
        }
    };
    const handleClickNotes = async () => {
        try {
            setActionState(0);
            if (insightDetails?.note_id === null) {
                const response = await createNote(pathIndex.channel_id, pathIndex.insight_id);
                if (response && response?.data) {
                    if (response?.data?.note_id) setNotesId(response?.data?.note_id);
                    setInsightDetails({ ...insightDetails, note_id: response?.data?.note_id });
                }
            }
            else {
                if (insightDetails?.note_id) setNotesId(insightDetails.note_id);
            }
        } catch (error) {
            console.error("Error updating insight details:", error);
        }
    };
    const handleClickShare = () => {
        setShowShareInsightModal(true);
    };


    const handleAddToNotes = async () => {
        try {
            if (insightDetails.note_id === null) {
                const response = await createNote(pathIndex.channel_id, pathIndex.insight_id);
                if (response && response?.data) {
                    setInsightDetails({ ...insightDetails, note_id: response?.data?.note_id });
                    const content = selectedText;
                    await addToNotes(insightDetails.channels[0], insightDetails.insight_id, content);
                    setLoadNotesTrigger(!loadNotesTrigger);
                    // Optional: Show a toast notification
                    toast('Added to notes!', {
                        position: "top-center",
                        theme: "info",
                        className: `${darkTheme ? "bg-black text-white" : "bg-white text-black"} py-3 px-4 rounded-lg`,
                        duration: 1500,
                    });
                }
                else {
                    toast(`🚨 Error while creating notes`, {
                        position: "top-center",
                        theme: "info",
                        className: `${darkTheme ? "bg-black text-red-400" : "bg-white text-red-600"
                            } py-3 px-4 rounded-lg flex items-center gap-2 border border-[#f15025]`,
                        style: {
                            fontSize: "0.95rem",
                            fontWeight: "500",
                        },
                        duration: 2000,
                    });
                }
            } else {
                const content = selectedText;
                await addToNotes(insightDetails.channels[0], insightDetails.insight_id, content);
                setLoadNotesTrigger(!loadNotesTrigger);
                // Optional: Show a toast notification
                toast('Added to notes!', {
                    position: "top-center",
                    theme: "info",
                    className: `${darkTheme ? "bg-black text-white" : "bg-white text-black"} py-3 px-4 rounded-lg`,
                    duration: 1500,
                });
            }

            // Always reset selection and actions
            setShowActions(false);
            setSelectedText("");
        } catch (error) {
            console.error("Error adding to notes:", error);
        }
    };

    if (loadingInsight) {
        return (
            <Suspense fallback={<div></div>}>
                <SkeletonInsights />
            </Suspense>
        );
    }

    if (!insightDetails) {
        return (
            <center
                className={`flex items-center justify-center text-center sm:w-[95%] w-full`}
            >
                <center
                    className={`text-center p-8 rounded-lg ${darkTheme ? "text-gray-100" : "text-gray-800"
                        }`}
                >
                    <h2 className={`text-xl font-semibold mb-4`}>
                        No Insight Details Available
                    </h2>
                    <p className="text-md font-light">
                        We're sorry, but it looks like there's nothing to show
                        right now.
                    </p>
                </center>
            </center>
        );
    }

    const { source_data, summary, url, created_at, is_public } = insightDetails;

    return (
        <div
            ref={summaryRef}
            className={`flex flex-col md:mx-[5px] lg:mx-5 my-2 w-[100%] text-[#ffffffea] h-screen  ${darkTheme ? "bg-[#181818] border-[#ffffff16]" : "bg-[#fafbfa]"
                }`}
        >
            <div className="flex-grow">
                {/* Insight Header */}
                <div className="flex flex-row mt-3 w-[95%]">
                    {insightDetails.source !== 'pdf' && <img
                        src={source_data.top_image}
                        alt="Top"
                        loading="lazy"
                        className="w-[140px] h-[120px] p-1 mt-4 sm:mt-0 sm:w-32 sm:h-28 sm:p-1 lg:w-56 lg:p-3 lg:h-40 rounded-md shadow-md"
                        onError={(e) => {
                            e.currentTarget.onerror = null;
                            e.currentTarget.src = "/logo.png";
                        }}
                    />
                    }
                    <div className={`mt-1 py-1  ${insightDetails.source === 'pdf' ? "" : "px-4"}`}>
                        <div className="flex items-center space-x-2 sm:p-1 sm:py-0 py-1">
                            <a
                                href={url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={`text-[20px] ${darkTheme
                                    ? "text-[#ffffffa3]"
                                    : "text-[#1a1a1aa3]"
                                    }`}
                            >
                                <h2
                                    className={`text-[17px] sm:text-[18px] lg:text-[24px] font-bold  ${darkTheme
                                        ? "text-white"
                                        : "text-black"
                                        } hover:text-blue-500 hover:underline transition-colors`}
                                >
                                    {source_data.title}
                                </h2>
                            </a>
                        </div>
                        <p
                            className={`mt-1 inline-flex sm:mt-2 sm:p-1 text-[12px] ${darkTheme
                                ? "text-[#ffffffa3]"
                                : "text-[#1a1a1aa3]"
                                }`}
                        >
                            <img
                                src={source_data.logo}
                                alt="Example"
                                loading="lazy"
                                className="w-4 h-4 mr-2"
                                onError={(e) => {
                                    e.currentTarget.onerror = null;
                                    e.currentTarget.src = "/logo.png";
                                }}
                            />
                            {source_data.published_date
                                ? format(
                                    new Date(source_data.published_date),
                                    "MMMM dd, yyyy"
                                )
                                : format(new Date(created_at), "MMMM dd, yyyy")}
                            {is_public.insight 
                            && 
                            <motion.div
                                className="flex justify-end relative cursor-pointer"
                                onHoverStart={() => setHoveredItem("globe")}
                                onHoverEnd={() => setHoveredItem(null)}
                            >
                                <GoGlobe className="w-4 h-4 text-blue-400 ml-4" />
                                {hoveredItem === "globe" && (
                                    <motion.div
                                        initial={{ opacity: 0, x: 10 }}
                                        animate={{ opacity: 1, x: 15 }}
                                        exit={{ opacity: 0, x: 10 }}
                                        className={`absolute right-[-90px] text-white px-3 py-1 text-[12px] rounded whitespace-nowrap ${darkTheme ? "bg-[#808080]" : "bg-[#808080]"}`}
                                        style={{ zIndex: 100 }}
                                    >
                                        Public insight
                                    </motion.div>
                                )}
                            </motion.div>
                            }
                        </p>
                        <motion.div className={`relative py-2 px-1 flex flex-row space-x-4 ${darkTheme ? "text-white" : "text-black"}`}>
                            <motion.div
                            >
                                <button onClick={handleChatClick} className={`flex flex-row space-x-2 px-4 py-2 rounded-full border border-[#f15025] ${darkTheme
                                    ? 'text-white'
                                    : 'text-black'
                                    } transition-colors`}>
                                    <p className={`text-[14px] ${darkTheme ? "text-white" : "text-black"}`}>Chat</p>
                                    <IoChatbubblesSharp
                                        className="text-[18px] mt-[2px] cursor-pointer text-green-700"
                                    />
                                </button>
                            </motion.div>
                            <motion.div
                            >
                                <button onClick={handleClickNotes} className={`flex flex-row space-x-2 px-4 py-2 rounded-full border border-[#f15025] ${darkTheme
                                    ? 'text-white'
                                    : 'text-black'
                                    } transition-colors`}>
                                    <p className={`text-[14px] ${darkTheme ? "text-white" : "text-black"}`}>Notes</p>

                                    <PiNotepadFill
                                        onClick={handleClickNotes}
                                        className="text-[18px] mt-[2px] cursor-pointer text-yellow-600"
                                    />
                                </button>
                            </motion.div>
                            <motion.div
                            >
                                <button onClick={handleClickShare} className={`flex flex-row space-x-2 px-4 py-2 rounded-full border border-[#f15025] ${darkTheme
                                    ? 'text-white'
                                    : 'text-black'
                                    } transition-colors`}>
                                    <p className={`text-[14px] ${darkTheme ? "text-white" : "text-black"}`}>Share</p>

                                    <IoShareSocialSharp
                                        onClick={handleClickShare}
                                        className="text-[18px] mt-[2px] cursor-pointer text-blue-600"
                                    />
                                </button>
                            </motion.div>
                        </motion.div>
                    </div>
                </div>

                {/* Insight Points */}
                <div
                    className={`py-3 border-b w-full md:px-[1px] lg:px-1 ${darkTheme
                        ? "text-[#ffffffa3] border-[#2a2929]"
                        : "text-[#1a1a1aa3] border-opacity-20 font-semibold"
                        }`}
                >
                    {summary.analysis.categories?.map((category, index) => (
                        <div key={index} className="mt-4">
                            {summary.analysis.insights.find(
                                (insight) => insight.category === category
                            ) && (
                                    <h3
                                        className={`text-[17px] md:text-[16px] lg:text-[20px] capitalize ${darkTheme ? "text-white" : "text-black"
                                            }`}
                                    >
                                        {category}
                                    </h3>
                                )}
                            {summary.analysis.insights.find(
                                (insight) => insight.category === category
                            ) && (
                                    <ul
                                        className={`mt-2 list-disc rounded-md px-1 ${darkTheme
                                            ? "text-[#ffffffa3]"
                                            : "text-[#1a1a1aa3]"
                                            }`}
                                    >
                                        {summary.analysis.insights
                                            .find(
                                                (insight) =>
                                                    insight.category === category
                                            )
                                            .points.map((point, idx) => (
                                                <li
                                                    key={idx}
                                                    className={`mx-3 sm:mx-2 text-[16px] sm:p-[5px] lg:p-2 font-normal sm:text-[13px] lg:text-[16px] ${darkTheme
                                                        ? "text-white"
                                                        : "text-black"
                                                        }`}
                                                >
                                                    {point}
                                                </li>
                                            ))}
                                    </ul>
                                )}
                        </div>
                    ))}
                </div>
            </div>

            {/* Selection Actions */}
            {showActions && (
                <div
                    className={`fixed rounded-lg px-2 py-1 text-[13px] flex gap-2 transform -translate-x-1/2 z-50 border ${darkTheme ? "bg-black border-[#404040]" : "bg-gray-200"}`}
                    style={{
                        left: `${position.x + 20}px`,
                        top: `${position.y - 45}px`,
                    }}
                >
                    <button
                        onClick={handleCopy}
                        className={`p-2 rounded-full transition-colors flex items-center gap-2 hover:text-[#f15025] ${darkTheme ? "text-white font-bold" : "text-black font-bold"}`}
                    >
                        <Copy className="w-4 h-4" />
                        <span>Copy</span>
                    </button>
                    <button
                        onClick={handleAddToNotes}
                        className={`p-2 rounded-full transition-colors flex items-center gap-2 hover:text-[#f15025] ${darkTheme ? "text-white font-bold" : "text-black font-bold"}`}
                    >
                        <BookMarked className="w-4 h-4" />
                        <span>Add to Notes</span>
                    </button>
                </div>
            )}
        </div>
    );
};

export default Summary;
