import axios from 'axios';
const axiosInstance = axios.create({
  baseURL: 'https://api.tensorweave.com/api/v1',
  headers: {
    'Content-Type': 'application/json',
    'ngrok-skip-browser-warning': 'true'
  }
});

export const getPublicInsightDetails = async (insightId) => {
    try {
      const response = await axiosInstance.get(`/insight/public/${insightId}`);
      return response.data;
    } catch (error) {
      console.error('Error creating channel:', error);
      return null;
    }
  };
