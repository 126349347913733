import React, { useContext, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { MdOutlineDashboard } from "react-icons/md";
import { RxTimer } from "react-icons/rx";
import { AiOutlineHome } from "react-icons/ai";
import { Context } from '../../Context/Context';
import { IoBookOutline, IoLogOutOutline } from 'react-icons/io5';
import { useLocation, useNavigate } from 'react-router-dom';
import { BsToggle2Off, BsToggle2On } from 'react-icons/bs';
import { CgProfile } from "react-icons/cg";

const SideNavigation = () => {
    const navigate = useNavigate();
    const [hoveredItem, setHoveredItem] = useState(null);
    const { darkTheme, toggleTheme, selectedSectionDesktop, setSelectedSectionDesktop, userData } = useContext(Context);
    const location = useLocation(); // Hook to get current path
    useEffect(() => {
        switch (location.pathname) {
            case '/home':
                setSelectedSectionDesktop('Home');
                break;
            case '/dashboard':
                setSelectedSectionDesktop('Dashboard');
                break;
            case '/history':
                setSelectedSectionDesktop('History');
                break;
            case '/profile':
                setSelectedSectionDesktop('Profile');
                break;
            default:
                setSelectedSectionDesktop('Home'); // Default fallback
                break;
        }
    }, [location.pathname]); // Run this effect whenever the path changes

    const menuItems = [
        { id: 'Home', icon: AiOutlineHome, label: 'Home', nav_link: '/home' },
        { id: 'Dashboard', icon: MdOutlineDashboard, label: 'Dashboard', nav_link: '/dashboard' },
        { id: 'History', icon: IoBookOutline, label: 'History', nav_link: '/history' },
    ];

    return (
        <motion.div
            className={`fixed left-0 top-0 h-screen w-[50px] 3xl:w-[50px] z-[12] border-r ${darkTheme ? "bg-[#202020] border-[#ffffff16]" : "bg-[#F6F5F3] border-[#3d3d3d2d]"} flex flex-col justify-between items-center`}
        >
            <motion.div 
                className={`mt-1 border-b  ${darkTheme ? "border-[#ffffff16]" : "border-[#00000016]"} cursor-pointer`}
                onClick={() => {
                    if(selectedSectionDesktop !== 'Home') navigate('/home')
                }}
            >
                <img src="/logo1.png" alt="" className={`w-[50px] h-[36px] my-auto ${darkTheme ? "" : "filter invert"}`} />
            </motion.div>

            <motion.div className="flex flex-col flex-grow items-center justify-center space-y-4">
                {menuItems.map((item) => (
                    <motion.div
                        key={item.id}
                        className={`w-full mb-1 relative ${selectedSectionDesktop === item.id ? 'text-[#f15025]' : ''}`}
                        onHoverStart={() => setHoveredItem(item.id)}
                        onHoverEnd={() => setHoveredItem(null)}
                        onClick={() => {
                            setSelectedSectionDesktop(item.id);
                            navigate(item.nav_link);
                        }}
                    >
                        <motion.div
                            className="flex items-center justify-center font-extralight rounded-lg cursor-pointer"
                            whileHover={{ scale: 1.1 }}
                        >
                            <item.icon className={`${item.id === 'History' ? "text-[19px]" : "text-[20px]" }`} />
                        </motion.div>
                        {selectedSectionDesktop === item.id && (
                            <motion.div
                                className="absolute left-0 top-0 w-[3px] ml-[-5px] h-full bg-[#f15025] rounded-r"
                                duration={2}   // Use `layout` for smooth transitions
                                transition={{ ease: "linear", duration: 1 }} // Customize the animation
                            />
                        )}
                        {hoveredItem === item.id && (
                            <motion.div
                                initial={{ opacity: 0, x: 10 }}
                                animate={{ opacity: 1, x: 0 }}
                                exit={{ opacity: 0, x: 10 }}
                                className={`absolute left-[-10px] top-8 text-white px-3 py-1 text-[12px] rounded whitespace-nowrap ${darkTheme ? "bg-[#808080]" : "bg-[#808080]"}`}
                                style={{ zIndex: 10 }}
                            >
                                {item.label}
                            </motion.div>
                        )}
                    </motion.div>
                ))}
            </motion.div>
            <motion.div className="flex flex-col items-center space-y-4 mb-8">
                <motion.div
                    className={`relative`}
                    onHoverStart={() => setHoveredItem("Profile")}
                    onHoverEnd={() => setHoveredItem(null)}
                    onClick={() => {
                        navigate('/profile');
                    }}
                >
                    <motion.div
                        className="flex items-center justify-center font-extralight rounded-lg cursor-pointer"
                        whileHover={{ scale: 1.1 }}
                    >
                        {userData ? <img
                            src={userData?.profile_image || "/logo.png"}
                            alt="Profile"
                            className={`w-7 h-7 rounded-full object-cover border-2 border-gray-200`}
                            /> : <CgProfile />}

                    </motion.div>
                    {hoveredItem === "Profile" && (
                        <motion.div
                            initial={{ opacity: 0, x: 10 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: 10 }}
                            className={`absolute left-[-10px] top-8 text-white px-3 py-1 text-[12px] rounded whitespace-nowrap ${darkTheme ? "bg-[#808080]" : "bg-[#808080]"}`}
                            style={{ zIndex: 10 }}
                        >
                            Profile
                        </motion.div>
                    )}
                </motion.div>


                <motion.div
                    className={`relative`}
                    onHoverStart={() => setHoveredItem("theme")}
                    onHoverEnd={() => setHoveredItem(null)}
                    onClick={toggleTheme}
                >
                    <motion.div
                        className="flex items-center justify-center font-extralight rounded-lg cursor-pointer"
                        whileHover={{ scale: 1.1 }}
                    >
                        {darkTheme ? <BsToggle2Off className='text-[20px]' /> : <BsToggle2On className='text-[20px]' />}
                    </motion.div>
                    {hoveredItem === "theme" && (
                        <motion.div
                            initial={{ opacity: 0, x: 10 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: 10 }}
                            className={`absolute left-[-10px] top-8 text-white px-3 py-1 text-[12px] rounded whitespace-nowrap ${darkTheme ? "bg-[#808080]" : "bg-[#808080]"}`}
                            style={{ zIndex: 10 }}
                        >
                            Switch to {darkTheme ? "Light" : "Dark"} Theme
                        </motion.div>
                    )}
                </motion.div>

                <motion.div
                    className={`relative`}
                    onHoverStart={() => setHoveredItem("logout")}
                    onHoverEnd={() => setHoveredItem(null)}
                    onClick={() => {
                        localStorage.removeItem('user_email');
                        localStorage.removeItem('access_token');
                        navigate('/');
                    }}
                >
                    <motion.div
                        className="flex items-center justify-center font-extralight text-red-500 rounded-lg cursor-pointer"
                        whileHover={{ scale: 1.1 }}
                    >
                        <IoLogOutOutline className='text-[20px]' />
                    </motion.div>
                    {hoveredItem === 'logout' && (
                        <motion.div
                            initial={{ opacity: 0, x: 10 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: 10 }}
                            className={`absolute left-[-10px] top-6 text-white px-3 py-1 text-[12px] rounded whitespace-nowrap ${darkTheme ? "bg-[#808080]" : "bg-[#808080]"}`}
                            style={{ zIndex: 10 }}
                        >
                            Logout
                        </motion.div>
                    )}
                </motion.div>
            </motion.div>

            {/* <Dropdown
                overlay={profileMenu}
                trigger={['click']}
                placement="topCenter"
                visible={profileMenuOpen}
                onVisibleChange={handleProfileMenuToggle}
            >
                <div
                    ref={profileMenuRef}
                    className={`cursor-pointer flex justify-left space-x-5 rounded-md p-2 transition-all duration-300 ease-in-out ${darkTheme
                        ? "hover:bg-[#3b3b3c] text-white"
                        : "hover:bg-[#e0e0e0] text-black"
                        } ${open ? "" : "ml-1 mr-1"}`}
                >
                    <div className={`flex items-center justify-between sm:space-x-8 md:space-x-10 lg:space-x-12 ${open ? "sm:mx-[-20px] lg:mx-[-15px] sm:pr-[4px] lg:pr-6" : " mx-[-18px] sm:pr-3 lg:pr-6"}`}>
                        <div className="flex items-center justify-between px-4 sm:space-x-1 lg:space-x-2">
                            {userData && <ProfileAvatar name={userData.name} size={28} />}
                            <div className="flex flex-col">
                                {userData && open && (
                                    <span className={`font-semibold ${darkTheme ? "text-white" : "text-black"}`}>
                                        {userData.name.split(' ')[0].charAt(0).toUpperCase() + userData.name.split(' ')[0].slice(1)}
                                    </span>
                                )}
                            </div>
                        </div>
                        {open && <HiOutlineDotsVertical />}
                    </div>
                </div>
            </Dropdown> */}

        </motion.div >
    );
};

export default SideNavigation;