import React, { useContext, useEffect, useRef, useState } from 'react';
import { motion } from "framer-motion";
import { Context } from '../../Context/Context';
import { HiArrowRight } from 'react-icons/hi';
import { Dialog, Transition } from '@headlessui/react';
import { createKnowledgeSpace } from '../../services/workspaceService';
import { createSpacePrompt } from '../../services/promptServices';
import { BsToggle2Off, BsToggle2On } from 'react-icons/bs';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

const SmartSpaceModal = () => {
    const { showSmartSpaceModal, setShowSmartSpaceModal, setPathIndex, pathIndex, setIsPromptLoading, knowledgespace } = useContext(Context);
    const [promptText, setPromptText] = useState('');
    const { darkTheme } = useContext(Context);
    const { setKnowledgespace } = useContext(Context);
    const [inputEmoji, setInputEmoji] = useState("default");
    const [loading, setLoading] = useState(false);
    const inputRef = useRef(null);
    const [placeHolderValue, setPlaceHolderValue] = useState('');
    const examplePrompts = [
        'I am researching on semiconductors, especially Graphcore, Nvidia, and Groq.',
        'Give me the latest on Perplexity and OpenAI from CNBC.'
    ];
    const location = useLocation();
    const navigate = useNavigate();

    const [currentPromptIndex, setCurrentPromptIndex] = useState(0);
    const [isTyping, setIsTyping] = useState(true);

    useEffect(() => {
        let currentText = '';
        let currentIndex = 0;
        let timeout;

        const typeText = () => {
            if (isTyping) {
                const targetText = examplePrompts[currentPromptIndex];
                if (currentIndex < targetText.length) {
                    currentText = targetText.slice(0, currentIndex + 1);
                    setPlaceHolderValue(currentText);
                    currentIndex++;
                    timeout = setTimeout(typeText, 50); // Typing speed
                } else {
                    setTimeout(() => setIsTyping(false), 1000); // Pause at the end
                }
            } else {
                if (currentIndex > 0) {
                    currentText = examplePrompts[currentPromptIndex].slice(0, currentIndex - 1);
                    setPlaceHolderValue(currentText);
                    currentIndex--;
                    timeout = setTimeout(typeText, 30); // Deletion speed (faster)
                } else {
                    setCurrentPromptIndex((prev) => (prev + 1) % examplePrompts.length);
                    setIsTyping(true);
                }
            }
        };

        timeout = setTimeout(typeText, 500);

        return () => clearTimeout(timeout);
    }, [currentPromptIndex, isTyping]);

    const handleClose = () => {
        setShowSmartSpaceModal(false);
        setInputEmoji("default");
        setPromptText("");
    };

    const handleDone = async () => {
        try {
            setLoading(true);
            handleClose();

            if (promptText.trim() === '') return;
            if (promptText.trim()) {
                const response = await createSpacePrompt(promptText.trim());
                if (response?.data) {
                    const spaceEntries = Object.entries(response.data.output); // Convert object to array of [key, value] pairs
                    const [spaceName, channelsArray] = spaceEntries.length > 0 ? spaceEntries[0] : ["Default Space", []];

                    // Get the first channel name or fallback to a default
                    const firstChannelName = channelsArray.length > 0 ? channelsArray[0] : "Default Channel";

                    // Update the state
                    setIsPromptLoading({
                        loading: true,
                        prompt_id: response.data.promptivity_id,
                        space_id: "prompt_space",
                        space_name: spaceName,
                        channel_name: firstChannelName,
                    });
                    const keys = Object.keys(response?.data.output);
                    const newKnowledgeSpaces = keys.map((key) => {
                        const newChannels = response?.data.output[key].map((channelName) => ({ name: channelName, id: "prompt_channel", unread_insights_count: 0, }));

                        const existingSpaceIndex = knowledgespace.findIndex(space => space.name === key);

                        if (existingSpaceIndex !== -1) {
                            const existingSpace = knowledgespace[existingSpaceIndex];

                            const existingChannelNames = new Set(existingSpace.channels.map(channel => channel.name));
                            const updatedChannels = [
                                ...existingSpace.channels,
                                ...newChannels.filter(channel => !existingChannelNames.has(channel.name)) // Add only new unique channels
                            ];

                            return {
                                ...existingSpace,
                                channels: updatedChannels,
                            };
                        } else {
                            return {
                                id: 'prompt_space',
                                name: key,
                                icon: inputEmoji,
                                path: ["Knowledge Space >>", key],
                                channels: newChannels,
                            };
                        }
                    });

                    setKnowledgespace((prevData) => {
                        const updatedData = [...prevData];

                        newKnowledgeSpaces.forEach((newSpace) => {
                            const existingSpaceIndex = updatedData.findIndex(
                                (space) => space.name === newSpace.name
                            );

                            if (existingSpaceIndex === -1) {
                                updatedData.push(newSpace); // Add new knowledge space to the front
                            } else {
                                updatedData[existingSpaceIndex] = newSpace; // Update existing space with merged channels
                            }
                        });

                        return updatedData;
                    });
                    setPromptText('');
                }
            }
        } catch (error) {
            console.log("Error:", error);
        } finally {
            setLoading(false);
            handleClose();
            if(location.pathname.includes('/history')){
                navigate('/home')
            }
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleDone();
        }
    };

    return (
        <Dialog open={showSmartSpaceModal} onClose={handleClose} className="relative z-[999] focus:outline-none" initialFocus={inputRef}>
            <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className={`fixed inset-0 ${darkTheme ? "bg-black" : "bg-white"} bg-opacity-65 backdrop-blur-md`} aria-hidden="true" />
            </Transition.Child>

            <div className="fixed inset-0 z-[999] mb-48 w-screen overflow-visible rounded-lg">
                <div className="flex min-h-full items-center justify-center p-4 text-center rounded-lg">
                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >

                        <Dialog.Panel
                            className={`relative transform overflow-visible rounded-xl transition-all sm:my-8 sm:max-w-3xl w-full ${darkTheme
                                ? 'bg-none text-white'
                                : 'bg-none text-black'
                                }`}
                        >
                            <p className={`text-[30px] py-4 ${darkTheme ? "text-gray-300" : ""}`}>What do you want to research about today?</p>
                            <div className={`items-center rounded-xl w-[100%] border border-[#f15025] ${darkTheme ? "bg-[#000]" : "bg-white"}`}>
                                {/*<div className={`flex items-center justify-between mb-1 border-b w-[100%] ${darkTheme ? "border-[#ffffff16]" : "bg-[#fafbfa]"}`}>
                                    <div className={`flex items-center gap-2 ${darkTheme ? "text-[#909090] border-[#ffffff16]" : "text-zinc-800"}`}>
                                        <h2 className="text-lg font-medium p-2">{isOldModal ? 'Tell us about the space you want to create' : 'Tell us about the space you want to create'}</h2>
                                        {!isOldModal && (
                                            <motion.div
                                                animate={{ x: [0, 4, 0] }}
                                                transition={{
                                                    duration: 1.5,
                                                    repeat: Infinity,
                                                    ease: "easeInOut"
                                                }}
                                            >
                                                <HiArrowRight className="h-5 w-5 text-[#f15025]" />
                                            </motion.div>
                                        )}
                                    </div> 
                                    <button
                                        type="button"
                                        onClick={handleClose}
                                        className="text-[#545657] hover:text-gray-500 pr-4 pt-1"
                                    >
                                        <RiCloseFill className="h-7 w-7" />
                                    </button>
                                </div>*/}

                                <div className="w-full rounded-xl p-6">
                                    <div className="flex flex-col gap-3">
                                        <textarea
                                            ref={inputRef}
                                            placeholder={placeHolderValue}
                                            className={`w-full px-3 bg-transparent border-none outline-none resize-none text-base ${darkTheme ? "text-zinc-200 placeholder-zinc-500" : "text-zinc-800 placeholder-zinc-400"}`}
                                            value={promptText}
                                            onChange={(e) => setPromptText(e.target.value)}
                                            onKeyDown={handleKeyDown}
                                        />
                                    </div>
                                </div>
                                <div className={`px-6 py-1 flex justify-end sm:px-6 border-t ${darkTheme ? "border-[#404040]" : ""}`}>
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center gap-2"></div>
                                        <button
                                            onClick={handleDone}
                                            className={`sm:p-2 3xll:p-4 rounded-full ${(promptText?.length > 0) ? "text-[#f15025] hover:text-[#f15025]" : "text-[#404040]"}`}
                                        >
                                            <motion.div
                                            >
                                                <HiArrowRight className="h-5 w-5" /> {/* Arrow pointing right */}
                                            </motion.div>
                                        </button>
                                    </div>

                                    {/* <button
                                        type="button"
                                        onClick={handleDone}
                                        className={`mt-3 inline-flex mx-2 justify-center rounded-md px-4 py-2 text-sm font-semibold shadow-sm ${darkTheme
                                            ? 'bg-blue-500 text-white hover:bg-blue-600'
                                            : 'bg-blue-600 text-white hover:bg-blue-700'
                                            }`}
                                        disabled={loading}
                                    >
                                        {loading ? (
                                            <ImSpinner2 className="animate-spin h-5 w-5 mr-2" />
                                        ) : (
                                            isOldModal ? 'Create Workspace' : 'Submit Prompt'
                                        )}
                                    </button> */}
                                </div>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
    );
};

export default SmartSpaceModal;
