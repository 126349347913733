import React, { useContext, useEffect, useState } from 'react';
import { IoSparklesSharp, IoSearch } from 'react-icons/io5'; // Import necessary icons
import { PlusOutlined } from '@ant-design/icons';
import { Context } from '../../Context/Context';
import LoginModal from '../../components/LoginModal/LoginModal';
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";
import { FiUsers } from "react-icons/fi";
import { IoBookOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { getUserPublicInsights } from '../../services/insightService';

const SidebarLogout = ({ peopleList, insight_id, user_name, isHistorySelected, setHistorySelected, setInsightList }) => {
    const { darkTheme, setShowSpaceModal, setShowSmartSpaceModal } = useContext(Context);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('access_token') !== null); // Toggle between logged-in and logged-out
    const [showPeopleDropdown, setShowPeopleDropdown] = useState(false); // Dropdown state
    const navigate = useNavigate();
    const handleOpenLoginModal = () => {
        setShowLoginModal(true);
    };
    const handleHistoryClick = () => {
        navigate(
            `/history`,
            { replace: true }
        );
    }
    const handleUserClick = async (username) => {
        try {
            const response = await getUserPublicInsights(username);
            if (response?.data) {
                const insights = response.data.insights.map((insight) => ({
                    id: insight.insight_id,
                    logo: insight.source_data.logo,
                    source: insight.source,
                    title: insight.source_data.title,
                    read: insight.read,
                    image: insight.source_data.top_image,
                    description: insight.description,
                    url: insight.url,
                    time: insight.created_at,
                }));

                insights.sort((a, b) => new Date(b.time) - new Date(a.time));
                navigate(`/user/${username}/in/${insights[0].id}`, { replace: true })
                setInsightList(insights);
                setHistorySelected(false);
            }
        }
        catch (error) {
            throw error;
        }
    }
    useEffect(() => {
        setIsLoggedIn(localStorage.getItem('access_token') !== null)
    }, [])

    const handleClickAddSmartSpace= async () => {
        setShowSmartSpaceModal(true);
    };
    const handleClickAddSpace = async () => {
        setShowSpaceModal(true);
    };


    return (
        <>
            <aside
                className={`h-screen fixed top-[5%] sm:top-[42px] border sm:left-[50px] 3xl:left-[50px] z-[10] ${darkTheme ? "bg-[#181818] border-[#ffffff16]" : "bg-[#fafbfa]"
                    } overflow-x-hidden hide-scrollbar w-[95%] sm:w-[185px] md:w-[195px] lg:w-56 3xl:w-64 xl:w-56 px-2`}
            >
                {/* Existing Design */}
                <div
                    className={`fixed w-[90%] sm:w-190px lg:w-[221px] 3xl:w-[254px] ml-[-8px] z-[6] border-b ${darkTheme
                        ? "bg-[#181818] border-[#ffffff16]"
                        : "bg-[#fafbfa] border-[#3d3d3d2d]"
                        }`}
                >
                    <div
                        className={`flex flex-row items-center justify-center w-[103%] my-[2px] justify-between border-b ${darkTheme ? "border-[#ffffff16]" : "border-[#3d3d3d2d]"
                            } pb-[1px] ml-[-4px]`}
                    >
                        <div
                            className={`${darkTheme
                                ? "text-white"
                                : "text-[#000000] font-semibold"
                                } p-2 sm:mx-2`}
                        >
                            Workspaces
                        </div>
                    </div>
                    <div className="pt-2"></div>

                    {/* Smart Research Button */}
                    <center
                        className={`p-[3px] ml-[2px] ${darkTheme
                            ? "hover:text-white hover:bg-[#f15025]"
                            : "hover:bg-[#f15025] hover:text-white"
                            }  rounded-lg`}
                    >
                        <span
                            onClick={() => {
                                console.log(isLoggedIn)
                                if (!isLoggedIn) {
                                    handleOpenLoginModal()
                                }
                                else {
                                    handleClickAddSmartSpace()
                                }
                            }
                            } // Trigger the function here
                            className={`cursor-pointer text-[15px] w-full ease-in-out`}
                        >
                            <div
                                className="p-1 flex flex-row space-x-4"
                            >
                                <IoSparklesSharp className="mt-1 ml-[6px]" />
                                <p className="ml-[-2px]">Smart Research</p>
                            </div>
                        </span>
                    </center>

                    {/* Add New Workspace Button */}
                    <center
                        className={`p-[3px] ml-[2px] ${darkTheme
                            ? "hover:!text-white hover:bg-[#f15025]"
                            : "hover:bg-[#f15025] hover:text-white"
                            }  rounded-lg`}

                        onClick={() => {
                            console.log(isLoggedIn)

                            if (!isLoggedIn) {
                                handleOpenLoginModal()
                            }
                            else {
                                handleClickAddSpace()
                            }
                        }
                        }

                    >
                        <span
                            className={`cursor-pointer text-[15px] w-full ease-in-out`}
                        >
                            <div className="p-1 flex flex-row space-x-4">
                                <PlusOutlined className=" ml-1" />
                                <p>Add New</p>
                            </div>
                        </span>
                    </center>

                    {/* Browse Search Button */}
                    <center
                        className={`p-[3px] ml-[2px] ${darkTheme
                            ? "hover:!text-white hover:bg-[#f15025]"
                            : "hover:bg-[#f15025] hover:text-white"
                            } rounded-lg`}
                    >
                        <span
                            // onClick={() => handleOpenLoginModal()} // Trigger the function here
                            className="cursor-pointer text-[15px] w-full ease-in-out"
                        >
                            <div className="p-1 flex flex-row space-x-4">
                                <IoSearch className="mt-1 ml-1" />
                                <p>Browse</p>
                            </div>
                        </span>
                    </center>
                    <div className="pb-2"></div>
                </div>

                {/* Additional Section for Logged-In Users */}
                {isLoggedIn && (
                    <div className="py-4 mt-[172px] ml-[-7px]">
                        <center
                            className={`p-[3px] ml-[2px] ${isHistorySelected ? "!text-white bg-[#f15025]" : ""} ${darkTheme
                                ? "hover:!text-white hover:bg-[#f15025]"
                                : "hover:bg-[#f15025] hover:text-white"
                                }  rounded-lg mb-1`}
                        >
                            <span
                                onClick={() => handleHistoryClick()} // Trigger the function here
                                className={`cursor-pointer text-[15px] w-full ease-in-out`}
                            >
                                <div className="p-1 flex justify-between">
                                    <div className='flex flex-row space-x-4'>
                                        <IoBookOutline className="mt-1 ml-1" /> <p className='text-[15px]'>History</p>
                                    </div>
                                    <p className='text-lg'><MdKeyboardArrowRight className="mt-1" /></p>
                                </div>
                            </span>
                        </center>
                        <center
                            className={`p-[3px] ml-[2px] rounded-lg mb-1 ${darkTheme
                                ? "hover:!text-white hover:bg-[#f15025]"
                                : "hover:bg-[#f15025] hover:text-white"
                                }`}
                        >
                            <span
                                className={`cursor-pointer text-[15px] w-full ease-in-out`}
                                onClick={async () => {
                                    setShowPeopleDropdown(!showPeopleDropdown);
                                    if (peopleList.length > 0 && !showPeopleDropdown) handleUserClick(peopleList[0].username)
                                }}
                            >
                                <div className="p-1 flex justify-between">
                                    <div className="flex flex-row space-x-4">
                                        <FiUsers className="mt-1 ml-1" />
                                        <p className="text-[15px]">People</p>
                                    </div>
                                    <p className="text-lg">
                                        {showPeopleDropdown ? (
                                            <MdKeyboardArrowDown className="mt-1" />
                                        ) : (
                                            <MdKeyboardArrowRight className="mt-1" />
                                        )}
                                    </p>
                                </div>
                            </span>
                        </center>
                        {showPeopleDropdown && (
                            <div className="space-y-2 pl-8">
                                {peopleList.map((user, idx) => (
                                    <div
                                        key={idx}
                                        className={`flex items-center space-x-4 px-2 py-1 rounded-lg justify-between ${darkTheme ? "text-white" : "text-black"
                                            } ${user_name === user.username
                                                ? "bg-[#f15025] text-white"
                                                : ""
                                            } hover:bg-[#f15025] hover:text-white cursor-pointer`}
                                        onClick={() => handleUserClick(user.username)}
                                    >
                                        <div className="flex justify-between space-x-4">
                                            <img
                                                src={
                                                    user.profile_image ||
                                                    `https://img.freepik.com/vecteurs-premium/icones-utilisateur-comprend-icones-utilisateur-symboles-icones-personnes-elements-conception-graphique-qualite-superieure_981536-526.jpg?semt=ais_hybrid`
                                                }
                                                alt={user.username}
                                                className="w-6 h-6 rounded-full object-cover"
                                            />
                                            <p className="font-medium text-sm">{user.username}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}
                <LoginModal
                    isOpen={showLoginModal}
                    handleClose={() => setShowLoginModal(false)}
                />
            </aside >
        </>
    );
};

export default SidebarLogout;
