import React, { useContext } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Context } from '../../Context/Context';

const SkeletonInsights = () => {
    const {darkTheme} = useContext(Context);
    return (
        <SkeletonTheme baseColor={`${darkTheme ? "#1e1e1e" : "#dddddd"}`} highlightColor="#444">
            <div className="mx-5 my-2 w-[90%] text-[#ffffffea]">
                <div className="flex flex-row">
                    <Skeleton className="w-56 p-3 h-40 rounded-md shadow-md mt-2" />
                    <div className="mt-3 p-1 w-full">
                        <Skeleton height={50} width="90%" />
                        <Skeleton height={20} width="30%" className="mt-2 p-1" />
                    </div>
                </div>
                <div className="py-3 w-full px-2">
                    <Skeleton height={20} width="30%" className="mt-4" />
                    <Skeleton count={6} className="mt-2 rounded-md shadow-md" />
                </div>
                <div className="py-3 w-full px-2">
                    <Skeleton height={20} width="30%" className="mt-4" />
                    <Skeleton count={5} className="mt-2 rounded-md shadow-md" />
                </div>
                <div className="py-3 w-full px-2">
                    <Skeleton height={20} width="30%" className="mt-4" />
                    <Skeleton count={4} className="mt-2 rounded-md shadow-md" />
                </div>
            </div>
        </SkeletonTheme>
    );
};

export default SkeletonInsights;