import React, { useContext } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Context } from '../../Context/Context';

const ProfilePageSkeleton = () => {
  const {darkTheme} = useContext(Context)
  return (
    <SkeletonTheme 
      baseColor={darkTheme ? "#1e1e1e" : "#dddddd"} 
      highlightColor="#444"
    >
      <div className="max-w-3xl mx-auto">
        <div className={`${darkTheme ? "bg-[#101010]" : "bg-white"} rounded-lg shadow p-6`}>
          {/* Header */}
          <div className="flex justify-between items-center mb-6">
            <Skeleton width={180} height={32} />
            <Skeleton width={120} height={40} className="rounded-lg" />
          </div>

          <div className="space-y-6">
            {/* Profile Picture Skeleton */}
            <div className="flex flex-col items-center space-y-4">
              <div className="relative">
                <Skeleton circle width={128} height={128} />
                <div className="absolute bottom-0 right-0">
                  <Skeleton circle width={32} height={32} />
                </div>
              </div>
            </div>

            {/* Profile Details Skeleton */}
            <div className="space-y-4">
              {/* Name Field */}
              <div>
                <Skeleton width={60} height={20} className="mb-2" />
                <Skeleton height={40} />
              </div>

              {/* Email Field */}
              <div>
                <Skeleton width={60} height={20} className="mb-2" />
                <Skeleton height={40} />
              </div>

              {/* Username Field */}
              <div>
                <Skeleton width={90} height={20} className="mb-2" />
                <Skeleton height={40} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default ProfilePageSkeleton;