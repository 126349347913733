import { Dialog, DialogPanel, DialogTitle, Transition } from '@headlessui/react';
import { Fragment, useContext, useCallback, useState, useEffect } from "react";
import { RiCloseFill } from "react-icons/ri";
import { Context } from "../../Context/Context";
import axiosInstance from "../../interceptors/auth.interceptor.js";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";

const LoginModal = ({ handleClose, isOpen }) => {
    const { darkTheme } = useContext(Context);
    const [isAuthenticating, setIsAuthenticating] = useState(false);
    const navigate = useNavigate();

    const redirectUri = "https://tensorweave.com/auth/google/callback";

    const handleAuthSuccess = useCallback(
        async (code) => {
            try {
                const response = await axiosInstance.get("/auth/auth/google", {
                    params: { code, redirect_uri: redirectUri },
                });

                const accessToken = response.data.data.access_token;
                const decodedToken = jwtDecode(accessToken);

                localStorage.setItem("access_token", accessToken);
                localStorage.setItem("user_email", decodedToken.email);
                localStorage.setItem("refresh_token", response.data.data.refresh_token);

                if (response.data.data.is_first_time) {
                    navigate("/onboarding");
                } else {
                    navigate("/home");
                }
            } catch (error) {
                console.error("Error during Google authentication:", error);
                // Handle error (e.g., show error message to user)
            } finally {
                setIsAuthenticating(false);
            }
        },
        [navigate, redirectUri]
    );

    const handleGoogleLogin = useCallback(async () => {
        if (isAuthenticating) return;

        try {
            setIsAuthenticating(true);
            const response = await axiosInstance.get("/auth/login/google", {
                params: { redirect_uri: redirectUri },
            });
            const authUrl = response.data.authorization_url;
            const width = 500;
            const height = 600;
            const left = window.screenX + (window.outerWidth - width) / 2;
            const top = window.screenY + (window.outerHeight - height) / 2.5;

            window.open(
                authUrl,
                "googleAuthPopup",
                `width=${width},height=${height},left=${left},top=${top}`
            );
        } catch (error) {
            console.error("Error during Google login initiation:", error);
            setIsAuthenticating(false);
        } finally {
            setIsAuthenticating(false);
        }
    }, [isAuthenticating, redirectUri]);

    useEffect(() => {
        const handleMessage = (event) => {
            if (event.origin !== window.location.origin) return;

            if (event.data.type === "GOOGLE_AUTH_SUCCESS") {
                handleAuthSuccess(event.data.code);
            }
        };

        window.addEventListener("message", handleMessage);
        return () => window.removeEventListener("message", handleMessage);
    }, [handleAuthSuccess]);

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog onClose={handleClose} className="relative z-[999]">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div
                        className={`fixed inset-0 ${darkTheme ? "bg-black" : "bg-white"} bg-opacity-55 backdrop-blur-md`}
                        aria-hidden="true"
                    />
                </Transition.Child>

                {/* Modal Panel */}
                <div className="fixed inset-0 z-[999] flex items-center justify-center p-4">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <DialogPanel
                            className={`relative transform overflow-hidden rounded-lg shadow-xl transition-all w-full max-w-md ${darkTheme
                                ? "bg-[#232325] text-white border-[1px] border-[#273037]"
                                : "bg-white text-black"
                                }`}
                        >
                            {/* Modal Header */}
                            <div className="px-6 py-4 flex items-center justify-between">
                                <DialogTitle className={`text-lg font-semibold ${darkTheme ? 'text-white' : 'text-gray-900'}`}>
                                    Login to Navigate Further
                                </DialogTitle>
                                <button
                                    type="button"
                                    onClick={handleClose}
                                    className="text-[#545657] hover:text-gray-500"
                                    aria-label="Close modal"
                                >
                                    <RiCloseFill className="h-6 w-6" />
                                </button>
                            </div>

                            {/* Modal Body */}
                            <div className="px-6 py-4 text-center">
                                <button
                                    onClick={handleGoogleLogin}
                                    className={`w-full py-2 rounded-md text-md font-medium transition-all duration-300 flex flex-row items-center justify-center gap-2 border border-blue-300 ${
                                        darkTheme
                                            ? "text-white hover:bg-[#3d4144]"
                                            : "bg-white text-black hover:bg-gray-100"
                                    }`}
                                    disabled={isAuthenticating}
                                >
                                    <FcGoogle/> Login with Google
                                </button>
                            </div>
                        </DialogPanel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
};

export default LoginModal;
