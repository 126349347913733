import React, { useContext, useState } from 'react';
import { motion } from 'framer-motion';
import { AiOutlineCalendar, AiOutlineBell, AiOutlineSetting } from 'react-icons/ai';
import { IoChatbubblesOutline } from 'react-icons/io5';
import { TbNotes } from "react-icons/tb";
import LoginModal from '../../components/LoginModal/LoginModal';
import { Context } from '../../Context/Context';

const RightSidebar = ({handleClickNotes, handleChatClick}) => {
    const {darkTheme} = useContext(Context)
    const [hoveredIcon, setHoveredIcon] = useState(null);
    const [showLoginModal, setShowLoginModal] = useState(false);

    const handleOpenLoginModal = () => {
        setShowLoginModal(true);
    };

    const icons = [
        { icon: IoChatbubblesOutline, label: 'Chat', func: handleChatClick, id: 1 },
        { icon: TbNotes, label: 'Notes', func: handleClickNotes, id: 0 },
    ];

    return (
        <>
            <div
                className={`fixed sm:right-0 3xl:right-0 top-[42px] transform  z-[22] sm:w-[50px] 3xl:w-[70px] h-screen bg-none border ${darkTheme ? "bg-[#181818] border-[#ffffff16]" : "bg-[#fafbfa] border-[#3d3d3d2d]"}`}
            >
                <div className=' flex justify-center items-center flex-col py-10'>

                    {icons.map(({ icon: Icon, label, func }, index) => (
                        <motion.div
                            key={index}
                            className="relative sm:p-3 cursor-pointer"
                            onHoverStart={() => setHoveredIcon(index)}
                            onHoverEnd={() => setHoveredIcon(null)}
                            onClick={func} // Open Login Modal
                        >
                            <div className="flex flex-row justify-between items-center">
                                <Icon className={`sm:w-5 sm:h-5 ${darkTheme ? 'text-[#808080]' : 'text-gray-600'}`} />
                            </div>

                            {hoveredIcon === index && (
                                <motion.div
                                    initial={{ opacity: 0, x: -10 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    className={`absolute right-full top-1/2 transform -translate-y-1/2 mr-2 px-2 py-1 text-[12px] text-white rounded 
                                    ${darkTheme ? "bg-[#808080]" : "bg-[#808080]"}`}
                                >
                                    {label}
                                </motion.div>
                            )}
                        </motion.div>
                    ))}
                </div>
            </div>

            {/* Login Modal */}
            <LoginModal isOpen={showLoginModal} handleClose={() => setShowLoginModal(false)} />
        </>
    );
};

export default RightSidebar;
